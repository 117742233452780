import React, { createContext, useState } from "react";
import axios from "axios";
import { useCoolDown } from "./hooks";
import { useSelector } from "react-redux";
import sendRequest from "./../../api";

function getUrl(query) {
    return `https://search.ecomarket.ru/index.php?r=site/old&query=${query.trim()}&categories=0&limit=18&location=77`
}

const useSearch = () => {
    const [query, setQuery] = useState('')
    const [results, setResults] = useState([])
    const products = useSelector(state => state.catalogue.products)
    const [loading, setLoading] = useState(true)

    // function filterProducts(ids = []) {
    //     return ids.filter(id => !!products[id])
    // }
    async function _onSearch(text = '') {
        if (text.trim() === '') {
            setLoading(false)
            return setResults([])
        }

        const response = await axios.get(getUrl(text)).then(r => r.data)
        const payload = { string: text };
        await sendRequest('recordSearchString', payload);
        setResults(response?.results?.ids || [])
        setLoading(false)
    }

    const onSearch = useCoolDown(_onSearch, 600)

    function onQueryChange(text) {
        setQuery(text)
        onSearch(text)
        setLoading(true)
    }

    return { query, onQueryChange, results, loading }
}

export const SearchContext = createContext({ query: '', onQueryChange: () => { }, results: [], loading: false })

export default useSearch
