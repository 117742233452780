import SText from "../../../components/SText";
import styles from "./index.module.scss";
import { APPLE_STORE, GOOGLE_PLAY, WEBSITE_DOMAIN } from "../../../constants/urls";
import Colors from "../../../components/utils/Colors";

const Referral = ({ promocode }) => {

    return (
        <div className={ styles.wrap }>
            <img src="/icons/referral/top.svg" className={ styles.top_svg } />
            <img src={ "/images/logo.svg" } className={ styles.logo } />
            <SText size={ 34 } weight={ 900 } color={ Colors.darkGray } style={ { marginTop: "12px" } }>Ecomarket</SText>
            <SText size={ 24 } weight={ 700 } color={ Colors.darkGray } style={ { marginTop: "42px", position: "relative", zIndex: 40 } }>
                Ð’Ð°Ñˆ Ð´Ñ€ÑƒÐ³ Ð¿Ð¾Ð´Ð°Ñ€Ð¸Ð» Ð²Ð°Ð¼<br />500 Ñ€ÑƒÐ±Ð»ÐµÐ¹ Ð½Ð° Ð¿ÐµÑ€Ð²Ñ‹Ð¹<br />Ð·Ð°ÐºÐ°Ð· Ð² MyFresh
            </SText>
            <div className={ styles.code_wrap }>
                <SText size={ 14 } weight={ 400 } color="#AEACA7">Ð’Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´:</SText>
                <div className={ styles.input_wrap }>
                    <SText size={ 22 } weight={ 700 }>{ promocode }</SText>
                    <button className={ styles.separator } onClick={ () => navigator.clipboard.writeText(promocode) }>
                        <img src={ "/icons/referral/copy.svg" } alt="copy" className={ styles.copy } />
                    </button>
                </div>
            </div>
            <SText size={ 18 } weight={ 700 } color={ Colors.darkGray } style={ { marginTop: "40px" } }>
                ÐŸÑ€Ð¸Ð¼ÐµÐ½Ð¸Ñ‚Ðµ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð½Ð° ÑÐ°Ð¹Ñ‚Ðµ
            </SText>
            <a className={ styles.website_link } href={ WEBSITE_DOMAIN }>
                <SText size={ 18 } weight={ 700 }>{ "myfresh.ru >>" }</SText>
            </a>
            <SText size={ 18 } weight={ 700 } style={ { marginTop: "15px" } }>Ð¸Ð»Ð¸ Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸</SText>
            <div className={ styles.stores }>
                <a href={ APPLE_STORE }>
                    <img src={ "/icons/apple_store_gray.svg" } alt="apple store" />
                </a>
                <a href={ GOOGLE_PLAY }>
                    <img src={ "/icons/google_play_gray.svg" } alt="google play" />
                </a>
            </div>
            <img src="/icons/referral/bottom.svg" className={ styles.bottom_svg } />
        </div>
    )
}

export default Referral;