import React, { useContext, useEffect, useState } from 'react'
import styles from './PaymentAndPromocode.module.scss'
import SText from "../../../components/SText";
import HelperContext from "../../../redux/HelperContext";
import Colors from "../../../components/utils/Colors";
import balls from '../../../assets/images/header/ecoBalls.svg'
import classNames from "classnames";
import PaymentMethod from "./PaymentMethod";
import success from '../../../assets/images/check-mark.svg'
import { colorToFilter } from "../../../components/utils/ColorFilter";
import { useWindowDimensions } from "../../../components/utils/hooks";
import arrow from '../../../assets/images/cart/goBack.svg'
import BottomModal from "../../../mobile/components/BottomModal";
import { Box, Modal } from "@mui/material";
import closeModal from '../../../assets/images/closeModal.svg'
import clearPromo from '../../../assets/images/cart/clearPromo.svg'

function getSelectedMethodLabel(selectedMethod, paymentMethods) {
    const methodData = paymentMethods.find(method => method.type === selectedMethod)
    if (selectedMethod.includes('card_')) {
        const cardLast = methodData?.last || ''
        return `ÐžÐ¿Ð»Ð°Ñ‚Ð° ÐºÐ°Ñ€Ñ‚Ð¾Ð¹ **** ${cardLast}`
    }
    if (!methodData) {
        return 'ÐžÐ¿Ð»Ð°Ñ‚Ð°'
    }
    return methodData?.label

}

const Switch = ({ onSwitch, isOn }) => {

    const [clicked, setClicked] = useState(isOn)

    const onSwitchClick = () => {
        onSwitch(!clicked)
        setClicked(prev => !prev)
    }

    return <div onClick={ onSwitchClick } style={ {
        background: clicked ? '#43b02a' : '#ededed',
        justifyContent: clicked ? 'flex-end' : 'flex-start'
    } } className={ styles.toggleBG }>
        <div className={ styles.toggle } />
    </div>
}

export const BonusesSelector = ({
    availableBonuses = 120, isOn = false, onSwitch = () => {
    }
}) => {
    const width = useWindowDimensions()

    if (availableBonuses === 0) return null

    /*if (width < 1024)*/
    return <div className={ styles.ecoWrapper }>
        <div className={ styles.ecoTextWrapper }>
            <SText size={ 14 } weight={ 500 } lineHeight={ 17 }>{ 'Ð¡Ð¿Ð¸ÑÐ°Ñ‚ÑŒ Ð±Ð¾Ð½ÑƒÑÐ½Ñ‹Ðµ Ð±Ð°Ð»Ð»Ñ‹' }</SText>
            <div style={ isOn ? { background: '#efeded' } : {} } className={ styles.ecoBalls }>
                <SText size={ 11 } weight={ 700 } lineHeight={ 20 }
                    color={ isOn ? 'rgb(127 127 127)' : '#fff' }>{ availableBonuses }</SText>
            </div>
        </div>
        <div>
            <Switch onSwitch={ onSwitch } isOn={ isOn } />
        </div>
    </div>

    return <div>
        <div className={ styles.selectorShape }>
            <div className={ classNames(styles.ecoBallsBtn, isOn ? '' : styles.activeBtn) }
                onClick={ () => onSwitch(false) }>
                <SText size={ 12 } weight={ 500 } lineHeight={ 20 }
                    color={ isOn ? '#bfbfbf' : Colors.darkGray }>{ 'ÐÐµ ÑÐ¿Ð¸ÑÑ‹Ð²Ð°Ñ‚ÑŒ' }</SText>
            </div>
            <div style={ { display: 'flex', alignItems: "center", justifyContent: 'center' } }
                className={ classNames(styles.ecoBallsBtn, !isOn ? '' : styles.activeBtn) }
                onClick={ () => onSwitch(true) }>
                <SText size={ 12 } weight={ 500 } lineHeight={ 20 } color={ !isOn ? '#bfbfbf' : Colors.darkGray }
                    style={ { marginRight: 5 } }>{ 'Ð¡Ð¿Ð¸ÑÐ°Ñ‚ÑŒ' }</SText>
                <div className={ styles.ecoBalls }>
                    <img src={ balls } />
                    <SText size={ 11 } weight={ 700 } lineHeight={ 20 } color={ '#fff' }>{ availableBonuses }</SText>
                </div>
            </div>

        </div>
    </div>
}

const PromoCode = ({ resetPromoCode, promoCodeData }) => {
    const { appendPromoCode } = useContext(HelperContext)
    const [value, setValue] = useState('')
    const [error, setError] = useState('')
    const [used, setUsed] = useState(false)

    const onTrySubmit = async () => {
        const r = await appendPromoCode(value)
        if (r?.promocode) {
            setUsed(true)
        } else {
            setError(r?.error || 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹. ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¿Ð¾Ð¿Ñ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ ÐµÑ‰Ðµ Ñ€Ð°Ð·')
        }
    }

    useEffect(() => {
        setError('')
    }, [value])

    return <div>
        <div style={ { marginBottom: 12 } }><SText size={ 14 } lineHeight={ 17 } weight={ 500 }
            color={ Colors.darkGray }>{ 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' }</SText></div>
        <div className={ styles.promoWrapper }>
            {
                promoCodeData.name ? <div style={ { display: 'flex', alignItems: 'center', gap: 12 } }>
                    <div style={ { display: 'flex', alignItems: 'center', gap: 4 } }><SText size={ 14 } lineHeight={ 17 }
                        weight={ 500 }
                        color={ Colors.green }>{ 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð¿Ñ€Ð¸Ð¼ÐµÐ½Ñ‘Ð½' }</SText>
                        <img style={ { filter: colorToFilter(Colors.green) } } src={ success } /></div>
                    <div style={ { borderBottom: '1px solid #bfbfbf', cursor: 'pointer' } } onClick={ resetPromoCode }><SText
                        size={ 14 } lineHeight={ 17 }
                        weight={ 500 }
                        color={ '#bfbfbf' }>{ 'ÐžÑ‚Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ' }</SText>
                    </div>
                </div> : <div className={ styles.promoInput }>
                    <input type="text" value={ value } style={ {
                        color: error ? Colors.red : Colors.darkGray,
                        border: error ? '1px solid #ff5252' : "none"
                    } }
                        placeholder={ 'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' }
                        onChange={ (e) => setValue(e.target.value) } />
                    <div onClick={ onTrySubmit }><SText size={ 14 } lineHeight={ 17 } weight={ 500 }
                        color={ '#fff' }>{ 'ÐŸÑ€Ð¸Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ' }</SText></div>
                </div>
            }
            { error !== '' && <SText className={ styles.errorPromo } size={ 11 } weight={ 500 } lineHeight={ 10 }
                color={ Colors.red }>{ error }</SText> }
        </div>
    </div>
}

const PaymentAndPromocode = ({ onPay }) => {
    const {
        paymentMethods,
        selectedMethod,
        totals,
        useBonuses,
        setUseBonuses,
        promoCodeData,
        resetPromoCode
    } = useContext(HelperContext)
    const availableBonuses = totals.availableBonuses
    const width = useWindowDimensions()
    const [promoModal, setPromoModal] = useState(false)
    const [cardsModal, setCardsModal] = useState(false)

    return <div style={ { marginBottom: 14 } }>
        <div style={ width > 1023 ? {} : { display: 'flex', justifyContent: 'space-between' } }>
            <div style={ { marginBottom: 11 } }><SText size={ 24 } lineHeight={ 30 } weight={ 700 }
                color={ Colors.darkGray }>{ 'ÐžÐ¿Ð»Ð°Ñ‚Ð°' }</SText></div>
            { width > 1023 &&
                <BonusesSelector onSwitch={ setUseBonuses } isOn={ useBonuses } availableBonuses={ availableBonuses } /> }
        </div>
        { promoCodeData.name ?
            <div style={ {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: width > 1023 ? '5px 0' : '15px 0',
                maxWidth: 257,
                minWidth: 257,
            } }>
                <div style={ { display: 'flex', alignItems: 'center', gap: 4 } }>
                    <SText size={ 15 } weight={ 500 } style={ { flex: 1 } } color={ Colors.green }>{ 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð¿Ñ€Ð¸Ð¼ÐµÐ½Ñ‘Ð½' }</SText>
                    <img style={ { filter: colorToFilter(Colors.green) } } src={ success } />
                </div>
                <div style={ { borderBottom: '1px solid #bfbfbf', cursor: 'pointer' } } onClick={ resetPromoCode }><SText
                    size={ 14 } lineHeight={ 17 }
                    weight={ 500 }
                    color={ '#bfbfbf' }>{ 'ÐžÑ‚Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ' }</SText>
                </div>
            </div>
            : <MenuItem onPress={ () => setPromoModal(true) } label={ 'Ð£ Ð¼ÐµÐ½Ñ ÐµÑÑ‚ÑŒ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' } /> }
        <MenuItem onPress={ () => setCardsModal(true) } label={ getSelectedMethodLabel(selectedMethod, paymentMethods) } />

        { cardsModal && <CardModal onClose={ () => setCardsModal(false) } /> }
        { promoModal && <PromoModal promoCodeData={ promoCodeData } resetPromoCode={ resetPromoCode }
            onClose={ () => setPromoModal(false) } /> }
    </div>
}

const MenuItem = ({
    label, onPress = () => {
    }
}) => {
    return <div className={ styles.menuItem } onClick={ onPress }>
        <SText size={ 15 } weight={ 500 } style={ { flex: 1 } }>{ label }</SText>
        <img src={ arrow } style={ { transform: 'rotate(180deg)' } } />
    </div>
}

export const CardModal = ({ onClose, onPay }) => {

    return <PaymentMethod onPay={ onPay } onClose={ onClose } />
}

export const PromoModal = ({ onClose, resetPromoCode, promoCodeData }) => {
    const { appendPromoCode } = useContext(HelperContext)
    const [value, setValue] = useState('')
    const [error, setError] = useState('')
    const [used, setUsed] = useState(false)
    const width = useWindowDimensions()

    const onTrySubmit = async () => {
        const r = await appendPromoCode(value)
        if (r?.promocode) {
            setUsed(true)
            onClose()
        } else {
            setError(r?.error || 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹. ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¿Ð¾Ð¿Ñ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ ÐµÑ‰Ðµ Ñ€Ð°Ð·')
        }
    }

    useEffect(() => {
        setError('')
    }, [value])

    if (width > 1024) {
        return <Modal
            open={ true }
            onClose={ onClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }
        >
            <Box className={ styles.modal }>
                <div><SText size={ 18 } weight={ 500 } lineHeight={ 22 } color={ '#515151' }>{ 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' }</SText></div>
                <div style={ { marginBottom: 3, visibility: error ? 'visible' : 'hidden' } }><SText size={ 10 } weight={ 500 } lineHeight={ 11 } color={ '#ff5252' }>{ 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹.' }</SText> <br /> <SText size={ 10 } weight={ 500 } lineHeight={ 11 } color={ '#ff5252' }>{ 'ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¿Ð¾Ð¿Ñ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ ÐµÑ‰Ðµ Ñ€Ð°Ð·' }</SText></div>
                <div className={ styles.promoInputDesk }>
                    <input autoFocus={ true } onKeyPress={ (e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            onTrySubmit()
                        }
                    } } type="text" value={ value } placeholder={ 'Ð’Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' }
                        onChange={ (e) => setValue(e.target.value) } />
                    { value && <img onClick={ () => setValue('') } className={ styles.clearPromo } src={ clearPromo } /> }
                </div>
                <div onClick={ onTrySubmit } style={ { opacity: (!value.length || error) ? .4 : 1, margin: 0 } }
                    className={ styles.submitBtn }><SText size={ 16 }
                        weight={ 500 }
                        lineHeight={ 20 }
                        color={ '#fff' }>{ 'ÐŸÑ€Ð¸Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ' }</SText>
                </div>
                <div onClick={ onClose } className={ styles.closeModal }><img src={ closeModal } /></div>
            </Box>
        </Modal>
    }

    return <BottomModal onClose={ onClose } maxHeight={ 330 }>
        <div style={ { marginLeft: 25, marginBottom: 20 } }><SText size={ 16 } weight={ 500 } lineHeight={ 20 }
            color={ Colors.darkGray }>{ 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' }</SText></div>
        <div className={ styles.promoInput }>
            <input type="text" value={ value } style={ {
                color: error ? Colors.red : Colors.darkGray,
                border: error ? '1px solid #ff5252' : "none"
            } } placeholder={ 'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' }
                onChange={ (e) => setValue(e.target.value) } />
            { error !== '' && <SText className={ styles.errorPromo } size={ 11 } weight={ 500 } lineHeight={ 10 }
                color={ Colors.red }>{ error }</SText> }
        </div>
        <div onClick={ onTrySubmit } style={ { background: !value.length ? '#979797' : Colors.green } }
            className={ styles.submitBtn }><SText size={ 16 }
                weight={ 500 }
                lineHeight={ 20 }
                color={ '#fff' }>{ 'ÐŸÑ€Ð¸Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ' }</SText>
        </div>
    </BottomModal>
}


export default PaymentAndPromocode