import React, { useState } from "react";
import { DIMENSIONS } from "../../../utils";
import styles from '../styles.module.css';
import userIcon from '../../../../assets/images/header/lk.svg'
import chevronDown from '../../../../assets/images/header/arrowDown.svg'
import SText from "../../../../components/SText";
import { useSelector } from "react-redux";
import moment from "moment";
import { prettyDate, shortAddress } from "../../../../components/utils/other";
import { NavLink, useSearchParams } from "react-router-dom";
import { BASE_DOMAIN } from "../../../../api";
import UserAccount from "../../UserAccount";

const SIZE = 44

export function getNearestDeliveryText({ date, time }) {
    if (!date && !time) return ''
    const slotMoment = moment(date, 'YYYY-MM-DD')

    const nearestTime = time.split('-')[0]

    return `Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ ${prettyDate(slotMoment).toLowerCase()} Ñ ${nearestTime}`
}

const Header = () => {
    const user = useSelector(state => state.user)
    const slots = useSelector(state => state.deliverySlots)
    const isSignedIn = Boolean(user.apikey)
    const [searchParams, setSearchParams] = useSearchParams()
    const [openLk, setOpenLk] = useState(false)

    const onUserClick = () => {
        if (user.apikey) setOpenLk(true)
        else setSearchParams('auth')
    }

    const formattedAddress = (user.address?.name_short || user.address.name) ? (user.address.name_short || user.address.name) + ' ' : 'Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ Ð°Ð´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸ '
    const closestSlot = slots.selectedSlot.date ? getNearestDeliveryText(slots.selectedSlot) : 'Ð˜ Ð¼Ñ‹ Ñ€Ð°ÑÑÑ‡Ð¸Ñ‚Ð°ÐµÐ¼ Ð²Ñ€ÐµÐ¼Ñ Ð¸ ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'

    return <div className={ styles.headerContainer } style={ { height: DIMENSIONS.HEADER_H } }>
        <div onClick={ onUserClick } className={ styles.userButton }>
            { (isSignedIn && user.profilePicture) ?
                <img style={ { objectFit: 'cover', width: 44, height: 44, borderRadius: 5 } }
                    src={ BASE_DOMAIN + user.profilePicture } /> :
                <img src={ userIcon } className={ styles.headerUserIcon } /> }
        </div>
        { openLk && <UserAccount onClose={ () => setOpenLk(false) } /> }
        <NavLink to={ '/map' }><div className={ styles.headerDeliveryBlock }>
            <SText size={ 19 } weight={ 900 } div color={ '#000000' } className={ styles.headerAddressText }>
                <div>{ formattedAddress }</div>
                <img src={ chevronDown } />
            </SText>
            <SText div size={ 12 } weight={ 500 } color={ '#000000' }>{ closestSlot }</SText>
        </div></NavLink>
    </div>
}

export default Header
