import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategoryName } from "../../components/utils/other";
import moment from "moment";

const initialState = {
    items: {},
    order: [],
    timings: {},
}

export const cartPlus = createAsyncThunk(
    'cart/cartPlus',
    async (id, { getState, dispatch }) => {
        if (typeof window.yaCounter99927635 !== "undefined") {
            window.yaCounter99927635.reachGoal('ADD_ORDER');
        }
        const product = getState().catalogue?.products?.[id]
        const currentAmount = getState().cart.items?.[id] || 0

        // try {
        //     window.dataLayer.push({ecommerce: null});
        //     window.dataLayer.push({
        //         'ecommerce': {
        //             'add': {
        //                 'products': [{
        //                     "id": product.id,
        //                     "name": product.title,
        //                     "price": product.price,
        //                     "quantity": 1,
        //                     'brand': product?.brand || null,
        //                     'category': getCategoryName(product.all_groups[0])?.title || null,
        //                 }]
        //             }
        //         },
        //         'event': 'gtm-ee-event',
        //         'event-GA4': 'add_to_cart',
        //         'gtm-ee-event-category': 'Enhanced Ecommerce',
        //         'gtm-ee-event-action': 'Add to Cart',
        //         'gtm-ee-event-non-interaction': 'False',
        //     });
        // } catch (error) {
        //     console.log(error)
        // }

        if (product?.availableones >= currentAmount + 1)
            dispatch(plus(id))
        else
            dispatch(setAmount({ id, amount: product?.availableones || 0 }))
    }
)

export const cartMinus = createAsyncThunk(
    'cart/cartMinus',
    async (id, { getState, dispatch }) => {
        /*        const PRODUCT = getState().catalogue.products[id]
                try {
                    window.dataLayer.push({ecommerce: null});
                    window.dataLayer.push({
                        'ecommerce': {
                            'remove': {
                                'products': [{
                                    "id": PRODUCT.id,
                                    "name": PRODUCT.title,
                                    "price": PRODUCT.price,
                                    "quantity": CART[PRODUCT.id]?.amount || 0,
                                    'brand': PRODUCT.brand || null,
                                    'category': getCategoryName(PRODUCT.all_groups[0]) || null,
                                }]
                            },
                            'items': [{
                                'item_name': PRODUCT.title,
                                'item_id': PRODUCT.id,
                                'item_brand': PRODUCT.brand || null,
                                'item_category': getCategoryName(PRODUCT.all_groups[0]) || null,
                                'item_category2': getCategoryName(PRODUCT.all_groups[1]) || null,
                                'item_category3': getCategoryName(PRODUCT.all_groups[2]) || null,
                                'item_category4': getCategoryName(PRODUCT.all_groups[3]) || null,
                                'item_category5': getCategoryName(PRODUCT.all_groups[4]) || null,
                                'item_list_name': action.from,
                                'item_list_id': item_list_id,
                                'price': PRODUCT.price,
                                'discount': PRODUCT.old_price !== 0 ? (PRODUCT.old_price - PRODUCT.price) : null, // ÐžÐ¿Ñ†Ð¸Ð¾Ð½Ð°Ð»ÑŒÐ½Ð¾
                                'quantity': CART[PRODUCT.id]?.amount || 0,
                            }]
                        },
                        'event': 'gtm-ee-event',
                        'event-GA4': 'remove_from_cart',
                        'gtm-ee-event-category': 'Enhanced Ecommerce',
                        'gtm-ee-event-action': 'Remove from Cart',
                        'gtm-ee-event-non-interaction': 'False',
                    });
                } catch {
                }*/

        dispatch(_cartMinus(id))
    }
)

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        plus: (state, action) => {
            if (state.items[action.payload]) {
                state.items[action.payload] += 1
            } else {
                state.items[action.payload] = 1
                state.order.push(action.payload)
            }
            state.timings[action.payload] = moment().format('DD-MM-YYYY HH:mm:ss')
        },
        setAmount: (state, action) => {
            state.items[action.payload.id] = action.payload.amount
        },
        _cartMinus: (state, action) => {
            if (state.items[action.payload]) {
                if (state.items[action.payload] > 1) {
                    state.items[action.payload] -= 1
                } else {
                    delete state.items[action.payload]
                    delete state.timings[action.payload]
                    state.order = state.order.filter(id => id != action.payload)
                }
            }
        },
        cartClear: state => {
            state.items = {}
            state.order = []
        },
        cartRemove: (state, action) => {
            delete state.items[action.payload]
            state.order = state.order.filter(id => id != action.payload)
        },
        cartSet: (state, action) => {
            state.items = action.payload.items
            state.order = action.payload.order
            state.timings = action.payload.timings || {}
        }
    }

})

export const { plus, _cartMinus, cartClear, cartRemove, setAmount, cartSet } = cartSlice.actions
export default cartSlice.reducer