import React from "react"
import styles from './About.module.scss'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import { NavLink } from "react-router-dom";
import { colorToFilter } from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";

const About = () => {

    return <div className={ styles.wrapper }>
        <div className={ styles.header }>
            <NavLink to={ '/' }>
                <div className={ styles.goBack }><img style={ { filter: colorToFilter('#383838') } } src={ back } /></div>
            </NavLink>
            <div><SText size={ 26 } lineHeight={ 20 } weight={ 700 } color={ Colors.black }>{ 'Ðž Ð½Ð°Ñ' }</SText></div>
        </div>
        <div className={ styles.container }>
            <div className={ styles.contentWrapper }>
                <div>
                    <div style={ { marginBottom: 16 } }>
                        <SText size={ 16 } lineHeight={ 20 }>{ 'ÐžÐ½Ð»Ð°Ð¹Ð½-Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð¿Ð¸Ñ‚Ð°Ð½Ð¸Ñ.' }</SText>
                    </div>
                    <div style={ { marginBottom: 16 } }>
                        <SText size={ 16 } lineHeight={ 20 }>{ 'Ð’ Ð½Ð°ÑˆÐµÐ¼ Ð°ÑÑÐ¾Ñ€Ñ‚Ð¸Ð¼ÐµÐ½Ñ‚Ðµ Ð²Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ Ð½Ð°Ð¹Ñ‚Ð¸ Ð²ÑÐµ Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ð¾Ðµ Ð´Ð»Ñ Ð¿Ð¾Ð»Ð½Ð¾Ñ†ÐµÐ½Ð½Ð¾Ð³Ð¾ Ð¸ Ð·Ð´Ð¾Ñ€Ð¾Ð²Ð¾Ð³Ð¾ Ð¿Ð¸Ñ‚Ð°Ð½Ð¸Ñ Ð½Ð° Ð»ÑŽÐ±Ð¾Ð¹ Ð²ÐºÑƒÑ Ð´Ð»Ñ Ð²ÑÐµÐ¹ ÑÐµÐ¼ÑŒÐ¸. Ð’ÑÐµ Ð½Ð°ÑˆÐ¸ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ð¸Ð¼ÐµÑŽÑ‚ Ð¿Ñ€Ð¾Ð·Ñ€Ð°Ñ‡Ð½ÑƒÑŽ Ð¸ÑÑ‚Ð¾Ñ€Ð¸ÑŽ Ð¿Ñ€Ð¾Ð¸ÑÑ…Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ Ð¸ Ð¾Ñ‚Ð²ÐµÑ‡Ð°ÑŽÑ‚ ÑÐ°Ð¼Ñ‹Ð¼ Ð²Ñ‹ÑÐ¾ÐºÐ¸Ð¼ ÑÑ‚Ð°Ð½Ð´Ð°Ñ€Ñ‚Ð°Ð¼ ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð°. ÐÐ°Ð´ÐµÐµÐ¼ÑÑ, Ñ‡Ñ‚Ð¾ Ð²Ð°Ð¼ Ð¿Ð¾Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ.' }</SText>
                    </div>
                </div>
                <img src="/images/company.jpg" />

            </div>
        </div>
    </div>
}

export default About