import React from "react";
import Colors from "./utils/Colors";

function getFontByThickness(thickness) {
    switch (thickness) {
        case 0:
            return 'DINPro-Light'
        case 2:
            return 'DINPro-Bold'
        case 3:
            return 'DINPro-Black'
        case 1: default: return 'DINPro-Medium'

    }
}

export function text(fontSize = 14, fontWeight = 400, fontType = 1, largerGap = false, color = Colors.darkBlue, lineHeight) {
    let type = 0
    if (fontWeight >= 500) {
        type = 1
        if (fontWeight >= 700)
            type = 2
        if (fontWeight >= 900)
            type = 3
    }
    return {
        fontFamily: getFontByThickness(type),
        fontSize: fontSize + 'px',
        color: color,
        lineHeight: lineHeight ? lineHeight + 'px' : largerGap ? '120%' : '100%',
    }
}

export default ({
    size,
    weight,
    color = Colors.black,
    fontType,
    gap,
    style = {},
    className = '',
    largerGap,
    lineHeight,
    div = false,
    ...props
}) => {
    return div ?
        <div className={ className }
            style={ {
                ...text(parseInt(size), weight, fontType, gap, largerGap, lineHeight),
                ...style,
                color: color,
            } }
            { ...props }
        >{ props.children }</div>
        : <span className={ className }
            style={ {
                ...text(parseInt(size), weight, fontType, gap, largerGap, lineHeight),
                ...style,
                color: color,
            } }
            { ...props }
        >{ props.children }</span>;
}
