export const dims = {
    width: window.innerWidth,
    height: window.innerHeight
}

export const DIMENSIONS = {
    CARD_H: Math.floor((dims.width - 31) / 2) + 132,
    CATEGORY_FONT: 22,
    SUBCATEGORY_FONT: 20,
    BANNER_HEIGHT: (dims.width - 32) / 2.5 + 32,
    TAG_FONT: 14,
    TAG_TITLE_H: 24,
    CATEGORY_TITLE_H: 36,
    SUBCATEGORY_TITLE_H: 36,
    SUBCATEGORY_CAROUSEL_H: 68,
    CATEGORY_CAROUSEL_H: 115,
    HEADER_H: 84,
    SEARCH_BAR_HEIGHT: 50
}

export function getCookie() {
    return document.cookie.split('; ').reduce((acc, item) => {
        const [name, value] = item.split('=')

        return { ...acc, [name]: value }
    }, {})
}

export function getCase() {
    let val = Math.round(Math.random())
    let BCcase = val === 1 ? 'B' : 'C'
    BCcase = 'C' // Disabling randomisation
    let cookie = getCookie()

    if (!cookie.BC) {
        document.cookie = 'testCase=' + BCcase
    }
    return BCcase
}