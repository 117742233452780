import React, { useEffect, useContext, useMemo } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { SearchContext } from "../../components/utils/search";

import goBack from '../../assets/images/cart/goBack.svg'
import notFounded from '../../assets/images/notFoundSearch.svg'
import styles from './Search.module.scss'
import SText from "../../components/SText";
import ProductCard from "../../screens/ProductCard/ProductCard";

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const { query, onQueryChange, loading, results } = useContext(SearchContext)
    const catalogue = useSelector(state => state.catalogue)

    useEffect(() => {
        const searchString = searchParams.get('q')
        onQueryChange(searchString)
    }, [])

    const filteredResults = useMemo(() => {
        const returnArray = []
        results.map(source_id => {
            for (let key in catalogue.products) {
                if (catalogue.products[key].source_id == source_id) {
                    returnArray.push(key)
                }
            }
        })
        return returnArray
    }, [results])

    return (
        <div className={ styles.wrapper }>
            <div className={ styles.header }>
                <div onClick={ () => navigate('/') } className={ styles.goBack }><img src={ goBack } /></div>
                <SText size={ 16 } lineHeight={ 16 } weight={ 500 }>{ 'Ð ÐµÐ·ÑƒÐ»ÑŒÑ‚Ð°Ñ‚Ñ‹ Ð¿Ð¾Ð¸ÑÐºÐ°' }</SText>
            </div>
            { query.length > 0 ?
                <div>
                    <h1>Ð ÐµÐ·ÑƒÐ»ÑŒÑ‚Ð°Ñ‚Ñ‹ Ð¿Ð¾Ð¸ÑÐºÐ° Ð¿Ð¾ Ð·Ð°Ð¿Ñ€Ð¾ÑÑƒ: { query }</h1>
                    { loading &&
                        <div style={ { display: 'grid', placeItems: 'center', paddingTop: 32 } }>
                            { 'ÐŸÐ¾Ð¸ÑÐº...' }
                        </div>
                    }
                    { !results.length && !loading &&
                        <div style={ { display: 'grid', placeItems: 'center', paddingTop: 32 } }>
                            <div style={ { textAlign: 'center' } }>
                                <SText size={ 18 } weight={ 700 } lineHeight={ 20 }>{ 'Ðš ÑÐ¾Ð¶Ð°Ð»ÐµÐ½Ð¸ÑŽ, ÑÑ‚Ð¾Ñ‚ Ñ‚Ð¾Ð²Ð°Ñ€' }</SText> <br />
                                <SText size={ 18 } weight={ 700 } lineHeight={ 20 }>{ 'Ð½Ðµ Ð¼Ð¾Ð¶ÐµÐ¼ Ð½Ð°Ð¹Ñ‚Ð¸ Ð¸ Ð¿Ñ€Ð¸Ð²ÐµÐ·Ñ‚Ð¸ Ð²Ð°Ð¼' }</SText>
                                <div style={ { marginTop: 25 } }><img src={ notFounded } /></div>
                            </div>
                        </div>
                    }
                    <div className={ styles.products }>
                        {
                            filteredResults.map((productId, index) => <div key={ index }><ProductCard string={ query } id={ productId } /></div>)
                        }
                    </div>
                </div>
                :
                <div>
                    <h1>Ð’Ñ‹ Ð½Ðµ ÑƒÐºÐ°Ð·Ð°Ð»Ð¸ Ð¿Ð¾Ð¸ÑÐºÐ¾Ð²Ñ‹Ð¹ Ð·Ð°Ð¿Ñ€Ð¾Ñ!</h1>
                </div>
            }
        </div>
    )
}

export default Search