import SText from "../../../../components/SText";
import styles from './styles.module.scss'
import { Products } from "../../../../screens/UserAccount/OrdersHistoryModal";
import { roundPrice } from "../../../../components/utils/other";
import { getTotals } from "../../../../screens/UserAccount/utils";
import React, { useState } from "react";
import greenTick from "../../../../assets/images/check-mark.svg";
import { colorToFilter } from "../../../../components/utils/ColorFilter";
import Colors from "../../../../components/utils/Colors";
import warning from "../../../../assets/images/warning.svg";
import AddCard from "../../../../screens/AddCardScreen/AddCard";
import SberPayButton from "../../../../assets/images/payments/sberPay.png";
import { createWidget } from "@sber-ecom-core/sberpay-widget";
import sendRequest from "../../../../api";
import { useSelector } from "react-redux";

const Details = ({ order, onPay, orderId, onClose }) => {
    const totals = getTotals(order)
    const [pay, setPay] = useState(false)
    const user = useSelector(state => state.user)
    const widget = createWidget("PRODUCTION");
    const onRegisterSberPay = async (id) => {

        const payload = {
            apikey: user.apikey,
            order: id,
            security: window?.security,
        }

        return await sendRequest('registerOrderSberPay', payload)
    }
    const handleClickSberPay = async () => {
        let resultRegister = await onRegisterSberPay(orderId);
        const params = {
            bankInvoiceId: resultRegister.payment_id,
            backUrl: 'https://myfresh.ru/sberpay_test',
        };
        widget.open(params);
        onClose();
    };
    return <div>
        <div className={ styles.deliveryDetails }>
            <SText div size={ 14 } weight={ 400 } style={ { marginBottom: 2 } }>{ 'Ð’Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸:' }</SText>
            <SText div size={ 14 } weight={ 400 } style={ { marginBottom: 12 } }>{ order.HUMAN_DATE + ' ' + order.TIME }</SText>

            <SText div size={ 14 } weight={ 400 } style={ { marginBottom: 2 } }>{ 'ÐÐ´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸:' }</SText>
            <SText div size={ 14 } weight={ 400 } style={ { marginBottom: 12 } }>{ order.ADRESS }</SText>

            <SText div size={ 14 } weight={ 400 } style={ { marginBottom: 2 } }>{ 'ÐžÐ¿Ð»Ð°Ñ‚Ð°:' }</SText>
            <SText div size={ 14 } weight={ 400 } style={ { marginBottom: 12 } }>{ order.PAYMENT_TITLE }</SText>

            { order.comment.trim() ? <SText div size={ 14 } weight={ 400 } color={ '#a0a0a0' }
                style={ { marginBottom: 12 } }>{ 'ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹: ' + order.comment }</SText> : null }

        </div>
        <div className={ styles.productsWrapper }>
            <Products products={ order.PRODUCTS } />
        </div>
        <Totals order={ order } />
        {/* { pay && <AddCard orderId={ orderId } onSuccess={ onPay } onClose={ () => setPay(false) } /> }

        { (order.PAID == 0 && order.newStatus !== 'cancelled') &&
            <div className={ styles.payButtons }>
                <div onClick={ () => setPay(true) } className={ styles.payBtn }>
                    <SText size={ 16 } weight={ 500 } color={ '#fff' }
                        lineHeight={ 16 }>{ 'ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ ' + roundPrice(totals.total) + ' â‚½' }</SText>
                </div>
                <div onClick={ handleClickSberPay }><img src={ SberPayButton } height={ 49 } /></div>
            </div>
        } */}
    </div>
}

const TotalRow = ({ title, value = 0, isFinal = false }) => {
    if (!value && !isFinal) return null

    return <div className={ styles.totalRow } style={ { marginTop: isFinal ? 20 : 6 } }>
        <SText size={ isFinal ? 15 : 14 } weight={ isFinal ? 700 : 400 }>{ title }</SText>
        <SText size={ isFinal ? 15 : 14 } weight={ isFinal ? 700 : 500 }>{ roundPrice(value) + ' Ñ€' }</SText>
    </div>
}


const Totals = ({ order, onPay }) => {
    const totals = getTotals(order)
    const [paymentModalVisible, setPaymentModalVisible] = useState(false)

    return <div className={ styles.deliveryDetails } style={ { marginTop: 2 } }>
        {/*<PaymentMethodModal visibilityState={[paymentModalVisible, setPaymentModalVisible]}
                            type={'afterPayment'} onPay={onPay}/>*/}
        <TotalRow title={ 'Ð§Ð°ÐµÐ²Ñ‹Ðµ ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ' } value={ totals.tips } />
        <TotalRow title={ 'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²' } value={ totals.productsSum } />
        <TotalRow title={ 'Ð˜Ð·Ð¼ÐµÐ½ÐµÐ½Ð¸Ñ Ð² Ð²ÐµÑÐµ' } value={ totals.weightDiff } />
        <TotalRow title={ 'Ð¡ÑƒÐ¼Ð¼Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸' } value={ totals.delivery } />
        <TotalRow title={ 'Ð’Ñ‹ Ð¿Ñ€Ð¸Ð¼ÐµÐ½Ð¸Ð»Ð¸ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´' } value={ totals.discount } />
        <TotalRow title={ 'ÐžÐ¿Ð»Ð°Ñ‡ÐµÐ½Ð¾ Ð±Ð¾Ð½ÑƒÑÐ½Ñ‹Ð¼Ð¸ Ð±Ð°Ð»Ð»Ð°Ð¼Ð¸' } value={ totals.bonuses } />
        <TotalRow title={ 'Ð˜Ñ‚Ð¾Ð³' } value={ totals.total } isFinal />
        { order.PAID == 1 && <div style={ { paddingBottom: 70 } } className={ styles.paymentStatusLabel }>
            <img src={ greenTick } style={ { filter: colorToFilter(Colors.green) } } />
            <SText color={ Colors.green } size={ 14 } weight={ 500 }>{ 'Ð—Ð°ÐºÐ°Ð· Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½' }</SText>
        </div> }
        { (order.PAID == 0 && order.newStatus !== 'cancelled' && order.PAYMENT != 1) &&
            <div className={ styles.paymentStatusLabel }>
                <img src={ warning } />
                <SText color={ Colors.red } size={ 14 } weight={ 500 }>{ 'Ð—Ð°ÐºÐ°Ð· Ð½Ðµ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½' }</SText>
            </div> }
        {/*{order.PAID == 1 && <View style={styles.paymentStatusLabel}>
            <CheckIcon {...icon(14, Colors.green, {marginRight: 6})}/>
            <SText color={Colors.green} size={14}>{'Ð—Ð°ÐºÐ°Ð· Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½'}</SText>
        </View>}
        {(order.PAID == 0 && order.newStatus !== 'cancelled') && <>
            <View style={styles.paymentStatusLabel}>
                <WarningIcon {...icon(14, Colors.red, {marginRight: 6})}/>
                <SText color={Colors.red} size={14}>{'Ð—Ð°ÐºÐ°Ð· Ð½Ðµ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½'}</SText>
            </View>
            <RoundedButton containerStyle={{borderRadius: 8, marginTop: 16}}
                           label={'ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ Ð·Ð°ÐºÐ°Ð·'}
                           onPress={()=>setPaymentModalVisible(true)}
            />
        </>}*/}
    </div>
}

export default Details
