import React, { useMemo } from "react";
import preloader from '../../assets/images/loader.gif'
import preloader_ny from '../../assets/images/loader_ny.gif'
import styles from './Preloader.module.scss'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";

const Preloader = () => {

    const preloader_data = useMemo(() => {
        const date = new Date;
        const month = date.getMonth() + 1;
        const day = date.getDate();

        if ((month == 12 && day >= 8) || (month == 1 && day < 5)) {
            return {
                source: preloader_ny,
                color: Colors.darkGreen,
                className: ''
            }
        }

        return {
            source: preloader,
            color: Colors.darkGray,
            className: ''
        }
    }, [])

    if (preloader_data.color == Colors.darkGreen) {
        return <div className={ styles.wrapper }>
            <img src={ preloader_data.source } className={ preloader_data.className } />
        </div>
    }

    return <div className={ styles.wrapper }>
        <img src="/images/logo.svg" className={ styles.pear } />
        {/* <br />
        <SText size={ 16 } lineHeight={ 20 } weight={ 500 } color={ preloader_data.color }>{ 'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° 24/7 Ð·Ð° 90 Ð¼Ð¸Ð½ÑƒÑ‚!' }</SText> */}
    </div>
}

export default Preloader