import React from "react";
import styles from '../OrderTradeOff.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { cartMinus, cartPlus } from "../../../redux/reducers/cart";
import { BASE_DOMAIN } from "../../../api";
import { getWeight, roundPrice } from "../../../components/utils/other";
import minus from '../../../assets/images/productCard/minus.svg'
import plus from '../../../assets/images/productCard/plus.svg'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";

const Item = ({ product, controllable = false }) => {
    const amount = useSelector(state => state.cart.items?.[product?.id || 0])
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    if (!product?.id) return null

    function onPlus(e) {
        e.stopPropagation()
        dispatch(cartPlus(product.id))
    }

    function onMinus(e) {
        e.stopPropagation()
        dispatch(cartMinus(product.id))
    }

    const isMax = product.availableones < amount + 1

    return <div className={ styles.productWrapper } onClick={ () => setSearchParams('product=' + product.id) }>
        <div style={ { display: 'flex', alignItems: 'center' } }>
            { product.thumb ? <img className={ styles.productImage } src={ BASE_DOMAIN + product.thumb } /> :
                <img className={ styles.productImage } src={ 'https://api.myfresh.ru/imgs/no-foto.png' } /> }
            <div>
                <div className={ styles.productTitle }>{ product.title || product.name }</div>
                <div className={ styles.productWeight }>
                    <div>{ getWeight(product.weight, product.ed_izm) }</div>
                    <div>{ roundPrice(product.price) + ' Ñ€' }</div>
                </div>
            </div>
        </div>
        { controllable && <div className={ styles.productController }>
            <div onClick={ onMinus } className={ styles.minus }><img src={ minus } /></div>
            <div><SText size={ 14 } weight={ 500 } lineHeight={ 17 } color={ Colors.darkGray }>{ amount }</SText></div>
            <div onClick={ onPlus } style={ { opacity: isMax ? .4 : 1 } } className={ styles.plus }><img src={ plus } /></div>
        </div> }
    </div>
}

export default Item