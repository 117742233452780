export default {
    green: '#536424',
    darkGreen: '#1d804e',
    yellow: '#ffbc3c',
    orange: '#FF7834',
    darkGray: '#383838',
    mediumGray: '#929292',
    cardGray: '#f8f8f8',
    lightGray: '#f2f2f2',
    red: '#ff5252',
    white: '#FFF'
}