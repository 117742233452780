import React, { useEffect } from 'react'
import {
    NavLink,
    Outlet, useSearchParams
} from "react-router-dom";
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import styles from "./AccountNavigationModal.module.scss";
import ecoBalls from "../../assets/images/header/ecoBalls.svg";
import help from "../../assets/images/userAccount/help.svg";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../redux/reducers/user";
import { Helmet } from "react-helmet";

const Account = () => {

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7'
        return () => {
            document.body.style.backgroundColor = '#fff'

        }
    }, [])

    return <div className={ styles.accountWrapper }>
        <AccountNavigation />
        <Outlet />
    </div>
}

const AccountNavigation = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    return <div className={ styles.leftBar }>
        <Helmet>
            <title>{ 'Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚' }</title>
        </Helmet>
        <NavLink onClick={ () => window.scrollTo(0, 0) } to={ '/account/orders-history' }>
            <div style={ { marginBottom: 27, display: 'flex', gap: 9, cursor: 'pointer' } }>
                <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.darkGray }>{ 'Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð·Ð°ÐºÐ°Ð·Ð¾Ð²' }</SText>
                { user.orders.unpaid > 0 &&
                    <div className={ styles.notPaidOrders }><SText size={ 14 } weight={ 700 } lineHeight={ 18 }
                        color={ '#fff' }>{ user.orders.unpaid }</SText></div> }
            </div>
        </NavLink>
        <NavLink onClick={ () => window.scrollTo(0, 0) } to={ '/account/bonuses' }>
            <div style={ { marginBottom: 27, display: 'flex', gap: 9, cursor: 'pointer' } }>
                <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.darkGray }>{ 'Ð‘Ð¾Ð½ÑƒÑÑ‹' }</SText>
                { user.bonuses > 0 && <div className={ styles.ecoBalls }>
                    <SText size={ 14 } weight={ 700 } lineHeight={ 20 } color={ '#fff' }>{ user.bonuses }</SText>
                </div> }
            </div>
        </NavLink>
        <NavLink onClick={ () => window.scrollTo(0, 0) } to={ '/account/personal-data' }>
            <div style={ { marginBottom: 27, cursor: 'pointer' } }><SText size={ 16 } weight={ 500 } lineHeight={ 20 }
                color={ Colors.darkGray }>{ 'ÐœÐ¾Ð¸ Ð´Ð°Ð½Ð½Ñ‹Ðµ' }</SText>
            </div>
        </NavLink>
        {/* <NavLink to={'/save-nature'}>
            <div style={{marginBottom: 45, cursor: 'pointer'}}><SText size={16} weight={500} lineHeight={20}
                                                                      color={Colors.darkGray}>{'Ð‘ÐµÑ€ÐµÐ¶Ñ‘Ð¼ Ð¿Ñ€Ð¸Ñ€Ð¾Ð´Ñƒ'}</SText>
            </div>
        </NavLink> */}
        <div className={ styles.separatedLine } />
        <div onClick={ () => setSearchParams('supportChat') } className={ styles.helpBtn }>
            <img src={ help } />
            <SText size={ 14 } lineHeight={ 14 } weight={ 700 } color={ '#fff' }>{ 'Ð¡Ð»ÑƒÐ¶Ð±Ð° Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸' }</SText>
        </div>
        <div className={ styles.separatedLine } />
        <NavLink to={ '/offer' }>
            <div style={ { marginBottom: 20, cursor: 'pointer' } }>
                <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.darkGray }>{ 'ÐžÑ„ÐµÑ€Ñ‚Ð°' }</SText>
            </div>
        </NavLink>
        <NavLink onClick={ () => window.scrollTo(0, 0) } to={ '/' }>
            <div onClick={ () => dispatch(signOut()) } style={ { cursor: 'pointer' } }>
                <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.red }>{ 'Ð’Ñ‹Ñ…Ð¾Ð´ Ð¸Ð· Ð°ÐºÐºÐ°ÑƒÐ½Ñ‚Ð°' }</SText>
            </div>
        </NavLink>
    </div>
}

export default Account