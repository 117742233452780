import React, { useEffect, useRef, useState } from "react";
import styles from '../styles.module.scss'
import { NavLink } from "react-router-dom";
import { colorToFilter } from "../../../../components/utils/ColorFilter";
import back from "../../../../assets/images/cart/goBack.svg";
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import PersonalDataD, { PaymentCards } from '../../../../screens/UserAccount/PersonalData'
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData, getPaymentCards, updateAddress, updateUserData } from "../../../../redux/reducers/user";
import { useCoolDown, useTextMask } from "../../../../components/utils/hooks";
import { BASE_DOMAIN } from "../../../../api";
import userZaglushka from "../../../../assets/images/header/lk.svg";
import addCard from "../../../../assets/images/userAccount/personalData/addCard.svg";
import AddCard from "../../../../screens/AddCardScreen/AddCard";

const phoneMask = ['+', '7', '-', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const PersonalData = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [phoneValue, setPhoneValue] = useState('')
    const ref = useRef(null)
    const [emailError, setEmailError] = useState(false)
    const [mapVisible, setMapVisible] = useState(false)
    const [addCardModal, setAddCardModal] = useState(false)

    const [data, setData] = useState({
        name: user.name,
        phone: user.phone,
        email: user.email
    })
    const [userInfo, setUserInfo] = useState({
        address: user.address
    })

    const setter = setUserInfo

    useEffect(() => {
        setUserInfo({
            address: user.address
        })
    }, [user])

    function compareUserInfo() {
        const isSame = (
            userInfo.address.number === user.address.number &&
            userInfo.address.entrance === user.address.entrance &&
            userInfo.address.floor === user.address.floor &&
            userInfo.address.comment === user.address.comment
        )
        if (isSame) return

        dispatch(updateAddress(userInfo.address))
    }

    const delayedCompareFn = useCoolDown(compareUserInfo, 1000)

    useEffect(() => {
        delayedCompareFn()
    }, [userInfo])

    function onAddressFieldChange(fieldName, value) {
        setter(prev => ({ ...prev, address: { ...prev.address, [fieldName]: value } }))
    }

    function validateAndSave() {
        const isValid = re.test(data.email)
        setEmailError(!isValid)

        if (data.name === user.name && data.phone === user.phone && data.email === user.email)
            return
        if (isValid) {
            dispatch(updateUserData({
                ...data
            }))
        }
    }

    useEffect(() => {
        setData({
            name: user.name,
            phone: user.phone,
            email: user.email
        })
    }, [user])

    useEffect(() => {
        if (data.email === user.email && data.name === user.name && data.phone === user.phone) return

        cooledDownSave()
    }, [data])

    const cooledDownSave = useCoolDown(validateAndSave, 1500)

    useTextMask(ref.current, { mask: phoneMask, guide: false })

    useEffect(() => {
        if (ref.current)
            ref.current.selectionStart = ref.current.selectionEnd = 20
    }, [phoneValue])

    const avatarChosen = async (e) => {
        let file = e.target.files[0];

        if (typeof file !== "undefined") {
            if (file !== null) {
                let SEND_DATA = new FormData();

                SEND_DATA.append('action', 'uploadAvatar');
                SEND_DATA.append('apikey', user.apikey);
                SEND_DATA.append('PICTURE', file);
                fetch(
                    'https://myfresh.ru/api.php',
                    {
                        credentials: 'include',
                        method: "POST",
                        body: SEND_DATA
                    }
                )
                    .then((response) => response.json())
                    .then(() => {
                        dispatch(fetchUserData())
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }

    return <div className={ styles.container }>
        <div className={ styles.header }>
            <NavLink to={ '/' }>
                <div className={ styles.goBack }><img style={ { filter: colorToFilter('#383838') } } src={ back } /></div>
            </NavLink>
            <div><SText size={ 16 } lineHeight={ 20 } weight={ 500 } color={ Colors.darkGray }>{ 'ÐœÐ¾Ð¸ Ð´Ð°Ð½Ð½Ñ‹Ðµ' }</SText></div>
        </div>


        <div style={ { marginBottom: 16 } }>{ user.profilePicture ? <img style={ { width: 71, height: 71, borderRadius: '50%' } }
            src={ BASE_DOMAIN + user.profilePicture } /> :
            <img style={ { width: 71, height: 71, borderRadius: '50%' } } src={ userZaglushka } /> }
        </div>
        <div className={ styles.inputFile }>
            <input id={ 'input_file' } type="file" onChange={ e => avatarChosen(e) } />
            <label htmlFor="input_file">{ 'Ð ÐµÐ´Ð°ÐºÑ‚Ð¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ Ñ„Ð¾Ñ‚Ð¾' }</label>
        </div>
        <div className={ styles.inputRow }>
            <input type="text" placeholder={ 'Ð’Ð°ÑˆÐµ Ð¸Ð¼Ñ' } onChange={ (e) => {
                setData({ ...data, name: e.target.value })
            } } value={ data.name } />
        </div>
        <div className={ styles.inputRow }>
            <input placeholder={ 'Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½' }
                onFocusCapture={ () => {
                    if (phoneValue === '') setPhoneValue('+7')
                } }
                ref={ ref }
                type={ 'cel-phone' }
                inputMode={ 'tel' }
                onFocus={ () => (phoneValue === '' && setPhoneValue('+7')) }
                maxLength={ 18 }
                onChange={ (e) => {
                    setData({ ...data, phone: e.target.value })
                } }
                value={ data.phone } />
        </div>
        <div className={ styles.inputRow }>
            <input onChange={ (e) => {
                setData({ ...data, email: e.target.value })
            } } type="text" value={ data.email } placeholder={ 'Ð’Ð°Ñˆ e-mail' } />
        </div>
        <div style={ { marginBottom: 20 } }><SText size={ 18 } lineHeight={ 23 } weight={ 700 }
            color={ Colors.darkGray }>{ 'ÐšÐ°Ñ€Ñ‚Ñ‹ Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹' }</SText>
        </div>
        <div onClick={ () => setAddCardModal(true) } style={ {
            display: 'flex',
            gap: 15,
            cursor: 'pointer',
            alignItems: 'center',
            marginBottom: 30,
            width: 'fit-content'
        } }>
            <img src={ addCard } />
            <SText size={ 14 } weight={ 500 } lineHeight={ 17 } color={ Colors.darkGray }>{ 'ÐÐ¾Ð²Ñ‹Ð¹ ÑÐ¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹' }</SText>
        </div>
        <PaymentCards />
        { addCardModal &&
            <AddCard onSuccess={ () => dispatch(getPaymentCards()) } onClose={ () => setAddCardModal(false) } />
        }
    </div>
}

export default PersonalData