import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import HelperContext from "../../redux/HelperContext";
import OrderProductXChange from "../../components/utils/OrderProductXChange";
import { useDispatch, useSelector } from "react-redux";
import { getTimeBeforeAssembly, useLastOrder } from "../../components/utils/hooks";
import moment from "moment";
import { refreshOrders } from "../../redux/reducers/user";
import Colors from "../../components/utils/Colors";
import styles from './HeaderCartButton.module.scss'
import CheckIcon from '../../assets/images/userAccount/statusBlock/accepted.svg'
import DeliveryIcon from '../../assets/images/userAccount/statusBlock/delivered.svg'
import BoxIcon from '../../assets/images/userAccount/statusBlock/assembled.svg'
import TruckIcon from '../../assets/images/header/cartButton/truck.svg'
import ReplaceIcon from '../../assets/images/header/cartButton/cart-plus.svg'
import CartIcon from '../../assets/images/header/cartButton/cart.svg'
import { useNavigate, useSearchParams } from "react-router-dom";
import { declOfNum, prettyDate, roundPrice } from "../../components/utils/other";
import SText from "../../components/SText";
import { colorToFilter } from "../../components/utils/ColorFilter";
import FloatingCart from "./components/FloatingCart/FloatingCart";

const HeaderCartButton = () => {
    const navigate = useNavigate()
    const { totals, delivery } = useContext(HelperContext)
    const { mode, remains: xChangeRemains } = useContext(OrderProductXChange)
    const dispatch = useDispatch()
    const cartSum = totals.cart.actual
    const cart = useSelector(state => state.cart)
    const lastOrder = useLastOrder()
    const [time, setTime] = useState(moment().format('mm'))
    const [timer, setTimer] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const [visibleCart, setVisibleCart] = useState(false)
    const location = window.location.pathname
    useEffect(() => { //
        if (timer) clearInterval(timer)
        if (!lastOrder) return

        setTimer(
            setInterval(() => {
                setTime((moment().format('mm')))
                dispatch(refreshOrders())
            }, 30000)
        )
    }, [lastOrder?.ID])

    function getMiddleButtonProps() {
        const DEL_PRICE = totals.deliveryPrice
        const MINIMAL_ORDER = delivery.minimalOrder
        const FREE_DEL_FROM = delivery.discountedFrom

        const remains = lastOrder?.arrival_time ? Math.ceil(Number(lastOrder?.arrival_time) / 60) + 5 : false

        if (mode === 'replace') {
            const hasCart = cart.order.length !== 0
            return {
                contentColor: '#fff',
                backgroundColor: hasCart ? Colors.green : Colors.red,
                mainText: hasCart ? `Ð—Ð°Ð¼ÐµÐ½Ð° Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð² ${cart.order.length ? `(+${cart.order.length})` : ''}` : 'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð·Ð°Ð¼ÐµÐ½Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²',
                auxText: `ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð¸ ${xChangeRemains.minutes()}:${String(xChangeRemains.seconds()).padStart(2, '0')}`,
                Icon: hasCart ? ReplaceIcon : false,
                iconSize: 32,
                onPress: () => setSearchParams('orderTradeOff')
            }
        }

        if (mode === 'add') {
            return {
                contentColor: '#fff',
                backgroundColor: Colors.green,
                mainText: cart.order.length ? 'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð² +' + cart.order.length : 'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¸Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²',
                auxText: `ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð¸ ${xChangeRemains.minutes()}:${String(xChangeRemains.seconds()).padStart(2, '0')}`,
                Icon: ReplaceIcon,
                iconSize: 32,
                onPress: () => setSearchParams('addProducts')
            }
        }

        if (!lastOrder && cartSum === 0) {
            return {
                contentColor: Colors.darkGray,
                backgroundColor: '#fff',
                mainTextStyle: {
                    size: 12,
                    weight: 500
                },
                auxTextStyle: {
                    size: 12,
                    weight: 500
                },
                Icon: TruckIcon,
                mainText: <div style={ { textAlign: 'center' } }>{ `ÐœÐ¸Ð½. ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° ${MINIMAL_ORDER} â‚½` }</div>,
                auxText: <span style={ { display: 'flex', alignItems: 'center', gap: 3 } }>{ `Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ Ð·Ð° ${DEL_PRICE} â‚½` }</span>,
                onPress: () => setSearchParams('deliveryInfo')
            }
        }

        if (cartSum > 0) {
            let auxText = ''
            if (cartSum >= MINIMAL_ORDER) {
                if (totals.deliveryPrice === 0) {
                    auxText = 'Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'
                } else {
                    let diff = FREE_DEL_FROM - cartSum
                    if (diff <= 500 && diff > 0)
                        auxText = delivery.discountedPrice === 0 ?
                            `${roundPrice(diff)} â‚½ Ð´Ð¾ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾Ð¹ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸` :
                            `${roundPrice(diff)} â‚½ Ð´Ð¾ ÑÐºÐ¸Ð´ÐºÐ¸ Ð½Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ`
                    else
                        auxText = <div style={ { textAlign: 'center' } }>{ `Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ Ð·Ð° ${totals.deliveryPrice} â‚½` }</div>
                }
            } else {
                auxText = <div style={ { textAlign: 'center' } }>{ `ÐœÐ¸Ð½. ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° ${MINIMAL_ORDER} â‚½` }</div>
            }

            return {
                contentColor: cartSum >= MINIMAL_ORDER ? '#fff' : Colors.darkGray,
                backgroundColor: cartSum >= MINIMAL_ORDER ? Colors.green : Colors.white,
                Icon: CartIcon,
                mainText: <div style={ { textAlign: 'center' } }>{ roundPrice(cartSum) + ' â‚½' }</div>,
                auxText: auxText,
                onPress: () => navigate('cart')
            }
        }

        if (lastOrder) {
            const minutes = getTimeBeforeAssembly(lastOrder)

            let props = {
                contentColor: '#fff',
                backgroundColor: Colors.green,
                Icon: lastOrder.newStatus === 'on_the_way' ? DeliveryIcon : BoxIcon,
                mainText: lastOrder.newStatus === 'on_the_way' ? 'ÐšÑƒÑ€ÑŒÐµÑ€ ÑƒÐ¶Ðµ Ð² Ð¿ÑƒÑ‚Ð¸!' : 'Ð¡Ð¾Ð±Ð¸Ñ€Ð°ÐµÐ¼ Ð²Ð°Ñˆ Ð·Ð°ÐºÐ°Ð·',
                auxText: remains ?
                    `ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ Ð¿Ñ€Ð¸Ð¼ÐµÑ€Ð½Ð¾ ${remains} ${declOfNum(remains, ['Ð¼Ð¸Ð½ÑƒÑ‚Ð°', 'Ð¼Ð¸Ð½ÑƒÑ‚Ñ‹', 'Ð¼Ð¸Ð½ÑƒÑ‚'])}` :
                    (
                        minutes ?
                            `ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ ~${minutes} ${declOfNum(minutes, ['Ð¼Ð¸Ð½ÑƒÑ‚Ð°', 'Ð¼Ð¸Ð½ÑƒÑ‚Ñ‹', 'Ð¼Ð¸Ð½ÑƒÑ‚'])}` :
                            lastOrder.newStatus === 'on_the_way' ? 'Ð¡ÐºÐ¾Ñ€Ð¾ Ð±ÑƒÐ´ÐµÑ‚ Ñƒ Ð²Ð°Ñ' : 'Ð¡ÐºÐ¾Ñ€Ð¾ Ð¿ÐµÑ€ÐµÐ´Ð°Ð´Ð¸Ð¼ Ð² Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ'
                    ),
                onPress: () => setSearchParams('orderDetails=' + lastOrder.ID)
            }

            if (minutes > 120) {
                props = {
                    ...props,
                    Icon: CheckIcon,
                    mainText: 'Ð’Ð°Ñˆ Ð·Ð°ÐºÐ°Ð· Ð¿Ñ€Ð¸Ð½ÑÑ‚',
                    auxText: `Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ ${prettyDate(moment(lastOrder.DATE, 'YYYY-MM-DD')).toLowerCase()} ${lastOrder.TIME}`,
                    iconSize: 32
                }
            }

            return props
        }
    }

    const middleButtonProps = useMemo(() => {
        return getMiddleButtonProps()
    }, [cartSum, lastOrder, delivery, time])

    const Icon = middleButtonProps.Icon

    if (!Boolean(middleButtonProps)) return <div />

    return <div onMouseOver={ () => setVisibleCart(true) } onMouseLeave={ () => setVisibleCart(false) } className={ styles.floatingContentWrapper }>
        <div className={ styles.wrapper } style={ { background: middleButtonProps.backgroundColor } }
            onClick={ middleButtonProps.onPress }>
            <div><img src={ Icon } style={ { filter: colorToFilter(middleButtonProps.contentColor) } } /></div>
            <div style={ { minWidth: '50%' } }>
                <div className={ styles.textDiv }><SText color={ middleButtonProps.contentColor } size={ middleButtonProps.mainTextStyle?.size || 16 }
                    weight={ middleButtonProps.mainTextStyle?.weight || 500 }
                    lineHeight={ 21 }>{ middleButtonProps.mainText }</SText></div>
                <div className={ styles.textDiv }><SText color={ middleButtonProps.contentColor } size={ middleButtonProps.auxText?.size || 12 }
                    weight={ middleButtonProps.mainTextStyle?.weight || 500 }
                    lineHeight={ 21 }>{ middleButtonProps.auxText }</SText></div>
            </div>
        </div>

        { (visibleCart && cartSum > 0 && location !== '/cart' && mode === 'none') && <FloatingCart onMouseLeave={ () => setVisibleCart(false) } /> }
    </div>
}

export default HeaderCartButton