import React, { useEffect, useState } from "react";
import styles from '../Pay/Pay.module.scss'
import sendRequest from "../../../api";
import { NavLink } from "react-router-dom";
import { colorToFilter } from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";

const Offer = () => {
    const [data, setData] = useState(null)

    useEffect(async () => {
        const data = await sendRequest("getPageData", { "url": "polzovatelskoe-soglashenie", "token": false })
        setData(data)
    }, [])

    if (!data) return null

    return <div className={ styles.wrapper }>
        <div className={ styles.header }>
            <NavLink to={ '/' }>
                <div className={ styles.goBack }><img style={ { filter: colorToFilter('#383838') } } src={ back } /></div>
            </NavLink>
            <div><SText size={ 26 } lineHeight={ 20 } weight={ 700 } color={ Colors.black }>{ 'ÐžÑ„ÐµÑ€Ñ‚Ð°' }</SText></div>
        </div>

        <div className={ styles.container } dangerouslySetInnerHTML={ { __html: data.content } } />
    </div>
}

export default Offer