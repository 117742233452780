import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../components/Header';
import { useSelector } from 'react-redux';
import GroupSelector from '../components/GroupSelector';
import styles from '../styles.module.scss'
import SText from "../../../../components/SText";
import greenArrow from '../../../../assets/images/catalogueV2/greenArrowRight.svg'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Colors from "../../../../components/utils/Colors";
import ProductCard from "../../../../screens/ProductCard/ProductCard";
import BottomBar from "../../Catalogue/components/BottomBar";
import BigList from "../../../../components/BigList";
import { DIMENSIONS } from "../../../utils";
import { ProductContainer, useWindowDimensions } from "../../Catalogue";
import { Helmet } from "react-helmet";

const ZERO_HEIGHT = 0;
const TAG_HEIGHT = 57;
const FULL_HEIGHT = 67;

function createMap(category, map) {
    let sections = []; // Ð´Ð»Ñ ÑÐ¿Ð¸ÑÐºÐ°
    let selectorList = []; // Ð´Ð»Ñ ÑÐµÐ»ÐµÐºÑ‚Ð¾Ñ€Ð° ÑÐ²ÐµÑ€Ñ…Ñƒ
    let info = []; // Ð´Ð»Ñ Ñ…ÑÐ´ÐµÑ€Ð¾Ð² ÑÐµÐºÑ†Ð¸Ð¹

    if (category.type === 'master') {
        for (let childId of category.children) {
            const child = map[childId];
            if (!child) continue;

            selectorList.push({
                index: sections.length,
                name: child.name,
                id: childId,
            });

            for (let tagId of child.children) {
                const tag = map[tagId];
                if (!tag?.products?.length) continue;

                sections.push(tag.products);
                info.push({
                    isFirst: tag.isFirst,
                    name: tag.name,
                    parentName: child.name,
                    id: tagId,
                    parentId: childId,
                    height: tag.isFirst ? FULL_HEIGHT : TAG_HEIGHT,
                });
            }
            if (child.products?.length) {
                sections.push(child.products);
                info.push({
                    isFirst: !child.children.length,
                    name: 'ÐŸÑ€Ð¾Ñ‡ÐµÐµ',
                    parentName: child.name,
                    id: childId,
                    parentId: childId,
                    height: TAG_HEIGHT,
                });
            }
        }
        if (category.products?.length) {
            sections.push(category.products);
            info.push({
                isFirst: false,
                name: null,
                parentName: null,
                id: category.id,
                parentId: null,
                height: ZERO_HEIGHT,
            });
        }

        return { sections, selectorList, info };
    }
    if (category.type === 'child') {
        for (let tagId of category.children) {
            const tag = map[tagId];
            if (!tag?.products?.length) continue;

            selectorList.push({
                index: sections.length,
                name: tag.name,
                id: tagId,
            });

            sections.push(tag.products);
            info.push({
                isFirst: false,
                name: tag.name,
                parentName: category.name,
                id: tagId,
                parentId: category.id,
                height: TAG_HEIGHT,
            });
        }

        if (category.products?.length) {
            sections.push(category.products);
            info.push({
                isFirst: false,
                name: category.children?.length ? 'ÐŸÑ€Ð¾Ñ‡ÐµÐµ' : null,
                parentName: null,
                id: category.id,
                parentId: null,
                height: category.children?.length ? TAG_HEIGHT : ZERO_HEIGHT,
            });
        }

        return { sections, info, selectorList };
    }
}

const fillCategoryWithPersonalProducts = (category, products, user) => {
    let useCategory = Object.assign({}, { ...category })
    if (!user || useCategory.id !== -100) {
        return useCategory
    }

    useCategory.products = user.personalRecommendations.filter(id => !!products[id]);

    return useCategory
}

const CategoryScreen = () => {
    const { url } = useParams();
    const user = useSelector(state => state.user)
    const products = useSelector(state => state.catalogue.products)
    const map = useSelector((state) => state.catalogue.catV6.map);
    const ids = useSelector(state => state.catalogue.urlMap[url])
    const id = map[ids.childId].url === url ? ids.childId : ids?.parentId

    const category = id == -100 ? fillCategoryWithPersonalProducts(map[id], products, user) : map[id];

    const listRef = useRef(null);
    const [pageTitle, setPageTitle] = useState('')
    const catalogue = useSelector(state => state.catalogue)
    const location = useLocation()


    useEffect(() => {
        setPageTitle({
            title: catalogue.categories.slave.map[ids.childId].metaTitle,
            keyWords: catalogue.categories.slave.map[ids.childId].metaKeywords,
            description: catalogue.categories.slave.map[ids.childId].metaDescription
        })

    }, [location.pathname])

    useEffect(() => {
        scrollToTop();
    }, [id]);
    /// add products to category.products
    const { selectorList, sections, info } = useMemo(() => createMap(category, map), [category, map]);
    const [visibleSection, setVisibleSection] = useState(selectorList[0]?.id);

    function renderSectionHeader(index) {
        const data = info[index];
        return <SectionHeader data={ data } />;
    }

    function scrollToIndex(index) {
        if (listRef.current)
            listRef.current.scrollToLocation({
                sectionIndex: index,
            });
    }

    function scrollToTop(animated = false) {
        if (listRef.current) {
            listRef.current.scrollToLocation({
                sectionIndex: 0,
            });
        }
    }

    const onViewableItemsChanged = useCallback(
        (viewableItems) => {
            const sectionIndex = viewableItems?.[0]?.sectionIndex;

            let visibleSection = false;

            for (let selectorItem of selectorList) {
                if (selectorItem.index <= sectionIndex) visibleSection = selectorItem.id;
                else break;
            }
            if (visibleSection) setVisibleSection(visibleSection);
        },
        [category]
    );

    const { width: windowWidth } = useWindowDimensions()

    const numColumns = useMemo(() => {
        return Math.floor(windowWidth / 260) > 2 ? Math.floor(windowWidth / 260) : 2
    }, [windowWidth])

    const width = useMemo(() => {
        return windowWidth / numColumns + 'px';
    }, [windowWidth, numColumns])

    const height = numColumns > 2 ? 380 : 395;

    return (
        <div>
            <Helmet>
                <title>{ pageTitle.title }</title>
                <meta name="viewport"
                    content="width=device-width, initial-scale=0.9, maximum-scale=0.9, user-scalable=no" />
                <meta name="description" content={ pageTitle.description } />
                <meta name="keywords" content={ pageTitle.keyWords } />
            </Helmet>
            <div style={ { background: '#fff', position: 'sticky', top: 0, zIndex: 20 } }>
                <div className={ styles.stickyHeader }>
                    <Header title={ category.name } />
                    <GroupSelector
                        selectorList={ selectorList }
                        activeId={ visibleSection }
                        scrollFunction={ scrollToIndex }
                    />
                </div>
            </div>

            <div>
                <BigList
                    sections={ sections }
                    ref={ listRef }
                    itemHeight={ height }
                    numColumns={ numColumns }
                    renderSectionHeader={ renderSectionHeader }
                    onViewableItemsChange={ onViewableItemsChanged }
                    sectionHeaderHeight={ (index) => info[index]?.height || 0 }
                    useWidth={ width }
                    renderItem={ (item, useWidth) => renderItem(item, useWidth, height) }
                />

                <NextButton nextId={ category.next } />

            </div>

            <BottomBar isOnTop={ false } />
        </div>
    );
};

function renderItem(item, width, height) {
    return <div style={ { height: height + 'px', width, padding: 5 } }><ProductCard id={ item } /></div>
}

const SectionHeader = ({ data }) => {
    const navigate = useNavigate();
    const map = useSelector((state) => state.catalogue.catV6.map[data.parentId]);
    if (!data.height) return null;

    function onNavigateToCat(id) {
        window.onChildClick = true
        window.scrollTo(0, 0)
        navigate(`/${map.url}`);
    }

    return (
        <div className={ styles.sectionHeaderContainer } style={ { height: data.height } }>
            { data.isFirst && (
                <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 6 } }>
                    <SText color={ '#929292' } size={ 20 } weight={ 900 } style={ { flex: 1 } }>
                        { data.parentName }
                    </SText>
                    <div className={ styles.moreBtn } onClick={ () => {
                        onNavigateToCat(data.parentId)
                    } }>
                        <SText size={ 14 } weight={ 700 } color={ Colors.green }>
                            { 'Ðš Ñ€Ð°Ð·Ð´ÐµÐ»Ñƒ' }
                        </SText>
                    </div>
                </div>
            ) }
            { data.name && (
                <SText size={ 14 } weight={ 700 } color={ '#929292' }>
                    { data.name }
                </SText>
            ) }
        </div>
    );
};

const NextButton = ({ nextId }) => {
    const map = useSelector((state) => state.catalogue.catV6.map);
    const next = map[nextId] || map[0];
    const navigate = useNavigate();

    function onPress() {
        window.scrollTo(0, 0)
        navigate(`/${next.url || map[0].url}`);
    }

    if (!next) {
        return null
    }

    return (
        <div className={ styles.nextCategoryWrapper }>
            <SText weight={ 500 } size={ 11 } color={ '#929292' }>
                { 'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ð´Ð°Ð»ÐµÐµ Ð² Ñ€Ð°Ð·Ð´ÐµÐ»' }
            </SText>
            <div onClick={ onPress } className={ styles.nextButton }>
                <SText size={ 14 } weight={ 700 }>{ next.name }</SText>
                <img src={ greenArrow } />
            </div>
        </div>
    );
};

export default CategoryScreen;