import { useSelector } from "react-redux";
import { BASE_DOMAIN } from "../../../../api";
import { useQueryParams } from "../../../../components/utils/ModalSpectator";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import Slider from "react-slick";
import React, { useRef } from 'react'
import { DIMENSIONS } from "../../../utils";



const Showcase = ({ mobile = false }) => {
    const slider = useRef(null)
    const config = useSelector(state => state.config.data)
    const navigate = useNavigate()

    const settings = {
        dots: mobile ? false : true,
        infinite: true,
        speed: 300,
        autoplaySpeed: 4000,
        fade: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const onClick = (item) => {
        if (typeof window.yaCounter99927635 !== "undefined") {
            window.yaCounter99927635.reachGoal('BANNER_CLICK');
        }
        navigate(item.link)
    }

    return <div style={ { height: DIMENSIONS.BANNER_HEIGHT, overflow: 'hidden' } }>
        <Slider ref={ slider } { ...settings } className="carousel">
            {
                config.map(item => <div key={ item.m_image } className={ styles.bannerImage } onClick={ () => onClick(item) }>
                    <img style={ { height: DIMENSIONS.BANNER_HEIGHT - 16 } } src={ BASE_DOMAIN + item.m_image } />
                </div>)
            }
        </Slider>
    </div>
}

export default Showcase
