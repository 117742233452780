import SText from '../../../components/SText'
import Colors from '../../../components/utils/Colors'
import styles from './DownloadAppModal.module.scss'
import closeBtn from "../../../assets/images/closeModal.svg";
import { GOOGLE_PLAY, APPLE_STORE } from '../../../constants/urls';

const DownloadAppModal = ({ isOpen, setClose }) => {
    return isOpen && (
        <div className={ styles.wrap }>
            <div className={ styles.container }>
                <div className={ styles.window }>
                    <SText size={ 24 } weight={ 500 } className={ styles.message }>Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾! ÐœÑ‹ Ð¿Ñ€Ð¸Ð½ÑÐ»Ð¸ Ð²Ð°Ñˆ Ð·Ð°ÐºÐ°Ð·!</SText>
                    <SText size={ 16 } weight={ 900 } className={ styles.text_center }>Ð¥Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð´Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ, Ð¾Ñ‚ÑÐ»ÐµÐ´Ð¸Ñ‚ÑŒ ÐºÑƒÑ€ÑŒÐµÑ€Ð° Ð¸Ð»Ð¸ ÑÐ²ÑÐ·Ð°Ñ‚ÑŒÑÑ <br></br> Ñ Ð´Ð¸ÑÐ¿ÐµÑ‚Ñ‡ÐµÑ€Ð¾Ð¼?</SText>
                    <SText size={ 20 } weight={ 900 } className={ styles.text_center } color={ Colors.green }>Ð­Ñ‚Ð¾ ÑƒÐ´Ð¾Ð±Ð½Ð¾ ÑÐ´ÐµÐ»Ð°Ñ‚ÑŒ <br></br> Ð² Ð½Ð°ÑˆÐµÐ¼ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸!</SText>
                    {/* <hr className={ styles.separator } />
                    <div className={ styles.text_wrap }>
                        <div className={ styles.column_text }>
                            <SText size={ 16 } weight={ 900 } className={ styles.text_center }>Ð¥Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð´Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ, Ð¾Ñ‚ÑÐ»ÐµÐ´Ð¸Ñ‚ÑŒ ÐºÑƒÑ€ÑŒÐµÑ€Ð° Ð¸Ð»Ð¸ ÑÐ²ÑÐ·Ð°Ñ‚ÑŒÑÑ <br></br> Ñ Ð´Ð¸ÑÐ¿ÐµÑ‚Ñ‡ÐµÑ€Ð¾Ð¼?</SText>
                            <SText size={ 20 } weight={ 900 } className={ styles.text_center } color={ Colors.green }>Ð­Ñ‚Ð¾ ÑƒÐ´Ð¾Ð±Ð½Ð¾ ÑÐ´ÐµÐ»Ð°Ñ‚ÑŒ <br></br> Ð² Ð½Ð°ÑˆÐµÐ¼ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸!</SText>
                            <div className={ styles.mobile }>
                                <img src={ "/images/mobile.png" } alt="mobile" />
                            </div>
                        </div>
                        <div className={ styles.column_text }>
                            <div className={ styles.text_icon }>
                                <img src={ "/images/petard.png" } alt="petard" className={ styles.petard } />
                                <SText size={ 16 } weight={ 500 } className={ styles.text_center } style={ { textAlign: "left" } }>ÐœÑ‹ Ñ‚Ð°ÐºÐ¶Ðµ Ð´Ð°Ñ€Ð¸Ð¼ Ð²Ð°Ð¼ 300 Ð±Ð¾Ð½ÑƒÑÐ½Ñ‹Ñ… Ð±Ð°Ð»Ð»Ð¾Ð² <br></br> Ð½Ð° Ð²Ð°Ñˆ Ð¿ÐµÑ€Ð²Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð·, Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð½Ñ‹Ð¹ Ñ‡ÐµÑ€ÐµÐ· Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ</SText>
                            </div>
                            <SText size={ 16 } weight={ 900 } className={ styles.text_center } color={ Colors.green }>ÐÐ°Ð²ÐµÐ´Ð¸Ñ‚Ðµ ÐºÐ°Ð¼ÐµÑ€Ñƒ ÑÐ¼Ð°Ñ€Ñ‚Ñ„Ð¾Ð½Ð° Ð½Ð° QR ÐºÐ¾Ð´ Ð´Ð»Ñ ÑÐºÐ°Ñ‡Ð¸Ð²Ð°Ð½Ð¸Ñ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ñ</SText>
                            <img src={ "/images/qr.png" } alt="qr" className={ styles.qr } />
                            <div className={ styles.app_markets }>
                                <a href={ APPLE_STORE }>
                                    <img src={ "/icons/apple_store.svg" } alt="apple store" />
                                </a>
                                <a href={ GOOGLE_PLAY }>
                                    <img src={ "/icons/google_play.svg" } alt="google play" />
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <img src={ closeBtn } alt="cross" className={ styles.cross } onClick={ () => setClose(false) } />
            </div>
        </div>
    )
}

export default DownloadAppModal