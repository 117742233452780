import React, { useState } from "react";
import styles from './styles.module.css'
import SText from "../../../../components/SText";
import { BASE_DOMAIN } from "../../../../api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PADDING_OUTER = 16
const GAP = 10
const SINGLE_TILE_SIZE = Math.floor((window.innerWidth - (2 * PADDING_OUTER + 2 * GAP)) / 3)
const DOUBLE_TILE_WIDTH = SINGLE_TILE_SIZE * 2 + GAP


const urlPrefix = BASE_DOMAIN + '/imgs/groups2/'

const Item = ({ subcategory }) => {

    const navigate = useNavigate()

    // const {navigate} = useNavigation()
    const [isDouble, setIsDouble] = useState(false)

    if (!subcategory) return null

    function onPress() {
        navigate('/' + subcategory.url)
    }

    function onLoad(event) {
        const ratio = event.target.naturalWidth / event.target.naturalHeight
        if (ratio > 1.8) setIsDouble(true)
    }

    return <div
        onClick={ onPress }
        style={ {
            width: isDouble ? DOUBLE_TILE_WIDTH : SINGLE_TILE_SIZE,
            height: SINGLE_TILE_SIZE,
            borderRadius: SINGLE_TILE_SIZE * 0.148,
            backgroundColor: '#eaeaea',
            marginRight: GAP,
            marginBottom: GAP,
        } }
        className={ styles.tile }>
        { subcategory.image2 ?
            <img src={ urlPrefix + subcategory.image2 }
                onLoad={ onLoad }
                style={ { width: '100%', height: '100%', objectFit: 'cover' } } /> :
            <SText div weight={ 700 } size={ 14 } style={ { margin: '13px 10px' } }>{ subcategory.title }</SText>
        }
    </div>
}

const CategoryGrid = ({ id }) => {
    const navigate = useNavigate()

    const category = useSelector(state => state.catalogue.categories.master.map[id])
    if (!category) return null

    const onClick = (category) => {
        navigate('/' + category.url)
    }

    return <div key={ id }>
        <SText onClick={ () => onClick(category) } div weight={ 900 } size={ 22 } style={ { marginBottom: 21, marginTop: 16, marginRight: 16 } }>{ category.name.toUpperCase() }</SText>
        <div className={ styles.grid }>
            { category.children.map(subcategory => <Item subcategory={ subcategory } key={ subcategory.id } />) }
        </div>
    </div>
}

const ExpandedList = () => {

    const data = useSelector(state => state.catalogue.categories.master.order)

    return <div className={ styles.list }>
        { data.map(id => <CategoryGrid id={ id } key={ id } />) }
    </div>
}

export default ExpandedList
