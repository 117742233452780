import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Header from "./components/Header";
import { useSelector } from "react-redux";
import SText from "../../../components/SText";
import BigList from "../../../components/BigList";
import { DIMENSIONS } from "../../utils";
import CatalogueCarousel, { SubCategoryCarousel } from "./components/CatalogueCarousel";
import styles from "../../../components/BigList/styles.module.css";
import ProductCard from "../../../screens/ProductCard/ProductCard";
import BottomBar from "./components/BottomBar";
import { useLocation } from "react-router-dom";
import Showcase from "./components/Showcase";
import search from '../../../assets/images/header/search.svg'
import { useQueryParams } from "../../../components/utils/ModalSpectator";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const ProductContainer = ({ id, width }) => {
    return <div style={ { height: 350, width, padding: 5 } }>
        <ProductCard id={ id } />
    </div>
}

function getHeaderHeight(index, infoMap) {
    const info = infoMap[index]

    return (info.renderCategoryName ? 30 : 0) + (info.renderSectionName ? 30 : 0)
}

const SectionHeader = ({ index }) => {
    const info = useSelector(state => state.catalogue.bigList.info[index])

    return <div className={ styles.sectionHeaderContainer }>
        { info.renderCategoryName && <h1 className={ styles.title } title={ info.categoryName }>{ info.categoryName }</h1> }
        { info.renderSectionName && <h2 className={ styles.subTitle } title={ info.sectionName }>{ info.sectionName }</h2> }
    </div>
}


const Catalogue = () => {
    const catalogue = useSelector(state => state.catalogue)
    const listRef = useRef(null)
    const [currentSection, setCurrentSection] = useState({ parent: 0, child: 0 })
    const [isOnTop, setOnTop] = useState(true)
    const location = useLocation()
    const { push } = useQueryParams()

    useEffect(() => {
        const ids = catalogue.urlMap[location.pathname.replaceAll('/', '')]
        if (!ids) return

        scrollToLocation(ids.childId)

    }, [location.pathname])

    const { width: windowWidth } = useWindowDimensions()

    const numColumns = Math.floor(windowWidth / 260) > 2 ? Math.floor(windowWidth / 260) : 2
    const width = 100 / numColumns + 'vw'

    const onViewableItemsChange = useCallback((items) => {
        const firstVisibleItem = items?.[0]
        if (firstVisibleItem?.sectionIndex !== undefined) {
            const subCat = catalogue.bigList.info[firstVisibleItem?.sectionIndex]
            setCurrentSection({
                parent: subCat.parentId,
                child: subCat.sectionId
            })
        }
    }, [catalogue.bigList.sections.length])

    function scrollToLocation(sectionId) {
        const index = catalogue.bigList.info.findIndex(section => section.sectionId == sectionId)
        listRef.current.scrollToLocation({
            sectionIndex: index
        })
    }

    return <>
        <Header />
        {/* <div style={ { backgroundColor: 'white', height: DIMENSIONS.BANNER_HEIGHT } }>
            <Showcase mobile={ true } />
        </div> */}
        <div className={ styles.inputBtnWrapper } style={ { height: DIMENSIONS.SEARCH_BAR_HEIGHT } }>
            <div onClick={ () => {
                push(['search', ''])
                window.initWithSearch = true
            } } className={ styles.inputBtn }>
                <img src={ search } />
                <SText size={ 14 } weight={ 400 } lineHeight={ 17 } color={ '#a6a6a6' }>{ 'ÐŸÐ¾Ð¸ÑÐº Ð¿Ð¾ ÐºÐ°Ñ‚Ð°Ð»Ð¾Ð³Ñƒ' }</SText>
            </div>
        </div>
        <div className={ styles.stickyPart }
            style={ { height: DIMENSIONS.CATEGORY_CAROUSEL_H + DIMENSIONS.SUBCATEGORY_CAROUSEL_H, backgroundColor: '#fff' } }>
            <CatalogueCarousel currentSection={ currentSection.parent } onSelect={ scrollToLocation } />
            <SubCategoryCarousel currentSection={ currentSection.child } onSelect={ scrollToLocation } />
        </div>
        <BigList sections={ catalogue.bigList.sections }
            ref={ listRef }
            renderItem={ (id) => <ProductContainer id={ id } width={ width } /> }
            itemHeight={ 350 }
            setOnTop={ setOnTop }
            numColumns={ numColumns }
            onViewableItemsChange={ onViewableItemsChange }
            listHeaderHeight={ DIMENSIONS.HEADER_H + DIMENSIONS.BANNER_HEIGHT + DIMENSIONS.SEARCH_BAR_HEIGHT }
            sectionHeaderHeight={ (index) => getHeaderHeight(index, catalogue.bigList.info) }
            renderSectionHeader={ (index) => <SectionHeader index={ index } /> }
        />
        <BottomBar isOnTop={ isOnTop } />
    </>

}


export default Catalogue
