import React, { useContext, useState, useEffect, useMemo } from "react";
import sendRequest from "../../../../api";
import { SearchContext } from "../../../../components/utils/search";
import { ProductContainer, useWindowDimensions } from "../../Catalogue";
import { useDetectKeyboardOpen } from "../../../../components/utils/hooks";
import styles from './styles.module.css'
import notFounded from '../../../../assets/images/notFoundSearch.svg'
import SText from "../../../../components/SText";
import { useSelector } from "react-redux";

const SearchResults = () => {
    const catalogue = useSelector(state => state.catalogue)
    const { results, onQueryChange, loading, query } = useContext(SearchContext)
    const [searchHints, setSearchHints] = useState([])
    const { width: windowWidth } = useWindowDimensions()
    const numColumns = Math.floor(windowWidth / 180)
    const width = 100 / numColumns + 'vw'

    const filteredResults = useMemo(() => {
        const returnArray = []
        results.map(source_id => {
            for (let key in catalogue.products) {
                if (catalogue.products[key].source_id == source_id) {
                    returnArray.push(key)
                }
            }
        })
        return returnArray
    }, [results, catalogue])

    useEffect(() => {
        sendRequest('getSearchRecommendations').then(response => {
            setSearchHints(response.sort((a, b) => a.sort - b.sort))
        })
    }, [])

    const isKeyboardOpen = useDetectKeyboardOpen();

    const isIos = useMemo(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return [
                'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'
            ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }

        return false;
    }, [])

    const height = useMemo(() => {
        if (isKeyboardOpen) {
            if (isIos) {
                return 'calc(100% - ' + parseInt(isKeyboardOpen) + 'px + 120px)';
            } else {
                return 'calc(100% - ' + parseInt(isKeyboardOpen) + 'px + 60px)';
            }
        }

        return 'auto';
    })

    if (!filteredResults.length && query.length < 2) {
        return <div className={ styles.list }><div style={ { width: '100%', height: height, overflowY: 'auto' } }>
            { searchHints.map((hint, index) => {
                return (
                    <a onClick={ () => onQueryChange(hint.name) } key={ index } className={ styles.hintLink }>
                        <SText size={ 16 } weight={ 500 }>{ hint.name }</SText>
                    </a>
                )
            }) }
        </div></div>
    }

    if (!filteredResults.length && !loading)
        return <div style={ { display: 'grid', placeItems: 'center', paddingTop: 32 } }>
            <div style={ { textAlign: 'center' } }>
                <SText size={ 18 } weight={ 700 } lineHeight={ 20 }>{ 'Ðš ÑÐ¾Ð¶Ð°Ð»ÐµÐ½Ð¸ÑŽ, ÑÑ‚Ð¾Ñ‚ Ñ‚Ð¾Ð²Ð°Ñ€' }</SText> <br />
                <SText size={ 18 } weight={ 700 } lineHeight={ 20 }>{ 'Ð½Ðµ Ð¼Ð¾Ð¶ÐµÐ¼ Ð½Ð°Ð¹Ñ‚Ð¸ Ð¸ Ð¿Ñ€Ð¸Ð²ÐµÐ·Ñ‚Ð¸ Ð²Ð°Ð¼' }</SText>
                <div style={ { marginTop: 25 } }><img src={ notFounded } /></div>
            </div>
        </div>

    if (loading) {
        return <div className={ styles.list } style={ { display: 'grid', placeItems: 'center', paddingTop: 32 } }>
            { 'ÐŸÐ¾Ð¸ÑÐº...' }
        </div>
    }

    return <div className={ styles.list }><div className={ styles.resultsGrid }>
        { filteredResults.map(id => {
            return <ProductContainer id={ id } width={ width } string={ query } />
        }) }
    </div></div>
}

export default SearchResults
