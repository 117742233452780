import React, { useContext, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CatalogueCarouselV2 from "../Catalogue/components/CatalogueCarouselV2";
import DeliveryBanner from "../../components/Banner/DeliveryBanner";
import { useQueryParams } from "../../components/utils/ModalSpectator";
import OrderProductXChangeContext from "../../components/utils/OrderProductXChange";
import Showcase from "../../components/Banner/Showcase";
import cn from "classnames";
import specificStyles from "./Catalogue.module.scss";
import styles from "../../components/BigList/styles.module.css";
import SubCategoryCarousel from "./components/SubCategoryCarousel";
import { DIMENSIONS } from "../../mobile/utils";
import ProductsCarousel from "../../mobile/screens/CatalogueV2/components/ProductsCarousel";
import { Helmet } from "react-helmet";

const CatalogueV3 = () => {
    const user = useSelector(state => state.user)
    const categories = useSelector(state => state.catalogue.catV6.masterOrder)
    const products = useSelector(state => state.catalogue.products)
    const { altProducts } = useContext(OrderProductXChangeContext)
    const recommended = useSelector(state => state.user.personalRecommendations)
    const navigate = useNavigate()
    const { push } = useQueryParams()
    const [showDeliveryBanner, closeDeliveryBanner] = useState(!user.apikey)

    function onCategoryPress(id) {
        navigate('Category', { id })
    }

    useEffect(() => {
        if (window?.newUserOrderId) {
            setTimeout(() => {
                push(['auth', window.newUserPhone])
            }, 200)
        } else if (window.orderId) {
            setTimeout(() => {
                push(['orderDetails', window.orderId])
            }, 200)
        }
    }, [window?.orderId, window?.newUserOrderId])

    let data = categories

    if (altProducts.length) {
        const additionalRowData = {
            name: 'Ð ÐµÐºÐ¾Ð¼ÐµÐ½Ð´ÑƒÐµÐ¼ Ðº Ð·Ð°Ð¼ÐµÐ½Ðµ',
            items: altProducts.filter(id => !!products[id]),
        }
        data = [additionalRowData, ...data]
    }

    if (recommended.length && false) {
        const additionalRowData = {
            name: 'Ð’Ñ‹ Ñ‡Ð°ÑÑ‚Ð¾ Ð±ÐµÑ€ÐµÑ‚Ðµ',
            items: recommended.filter(id => !!products[id]),
        }
        data = [additionalRowData, ...data]
    }

    return (
        <>
            <Helmet>
                <title>Ð¤Ð Ð­Ð¨ - Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²</title>
                <meta name="description"
                    content="Ð’ Ð¸Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚ Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ Ð¤Ð Ð­Ð¨ Ð²Ñ‹ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ ÐºÑƒÐ¿Ð¸Ñ‚ÑŒ Ñ„ÐµÑ€Ð¼ÐµÑ€ÑÐºÐ¸Ðµ Ð¾Ð²Ð¾Ñ‰Ð¸, Ñ„Ñ€ÑƒÐºÑ‚Ñ‹, Ð¼ÑÑÐ¾, Ð¿Ñ‚Ð¸Ñ†Ñƒ, Ð¼Ð¾Ð»Ð¾ÐºÐ¾ Ð¸ Ð¼Ð½Ð¾Ð³Ð¾Ðµ Ð´Ñ€ÑƒÐ³Ð¾Ðµ."
                    data-react-helmet="true" />
                <meta name="keywords" content="ÐœÑ‹ Ð¿Ñ€Ð¾Ð´Ð°ÐµÐ¼ Ñ‚Ð¾Ð»ÑŒÐºÐ¾ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ðµ, Ð²ÐºÑƒÑÐ½Ñ‹Ðµ Ð¸ Ð¿Ð¾Ð»ÐµÐ·Ð½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¾Ð¹ Ð½Ð° Ð´Ð¾Ð¼ Ð¿Ð¾ Ð¾Ñ‚Ð»Ð¸Ñ‡Ð½Ñ‹Ð¼ Ñ†ÐµÐ½Ð°Ð¼!" data-react-helmet="true" />
                <meta name="viewport"
                    content="width=device-width, initial-scale=0.9, maximum-scale=0.9, user-scalable=no" />
            </Helmet>
            <div style={ { backgroundColor: 'white' } }>
                <Showcase />
            </div>
            { showDeliveryBanner && <DeliveryBanner show={ closeDeliveryBanner } /> }
            {/* <div style={ { position: 'sticky', top: 100, left: 0, zIndex: 2, background: '#fff', padding: '0 0 5px' } }>
                <CatalogueCarouselV2 currentSection={ -1 } setCurrentSection={ (id) => onCategoryPress(id) } />
            </div> */}
            {/* <div className={ styles.initPage }>
                <h1>Ð­ÐºÑÐ¿Ñ€ÐµÑÑ-Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ° ÑÐ²ÐµÐ¶Ð¸Ñ… Ð²ÐºÑƒÑÐ½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²</h1>
                <img src="/images/pear.svg" />
            </div> */}
            {
                data.map((item, index) => {
                    if (item.items && item.name)
                        return <ProductsCarousel key={ index } name={ item.name } items={ item.items } />
                    else
                        return <ProductsCarousel key={ index } categoryId={ item } />;
                })
            }
        </>
    );
}

export default CatalogueV3