import React, { useState } from 'react'
import { useSelector } from "react-redux";
import styles from './CatalogueNavigator.module.scss'
import SText from "../SText";
import Colors from "../utils/Colors";
import { BASE_DOMAIN } from "../../api";
import { ClickAwayListener } from "@mui/base";
import { useNavigate } from "react-router-dom";

const ImageBtn = ({ subCategory, onClose }) => {

    let [isDouble, setIsDouble] = useState(false)
    const navigate = useNavigate()

    function onLoad(event) {
        const ratio = event.target.naturalWidth / event.target.naturalHeight
        if (ratio > 1.8) setIsDouble(true)
    }

    const onClick = () => {
        navigate('/' + subCategory.url)
        onClose()
    }

    if (subCategory.image2)
        return <img onClick={ onClick } className={ isDouble ? styles.doubleImg : styles.img }
            src={ BASE_DOMAIN + '/imgs/groups2/' + subCategory.image2 } onLoad={ onLoad } />

    return <div onClick={ onClick } style={ { padding: 9 } } className={ styles.img }><SText size={ 14 } weight={ 700 }
        color={ Colors.darkGray }
        lineHeight={ 16 }>{ subCategory.title }</SText>
    </div>
}

const CatalogueNavigator = ({ onClose }) => {
    const data = useSelector(state => state.catalogue.categories.master.order)
    const categories = useSelector(state => state.catalogue.categories.master.map)
    const navigate = useNavigate()

    const onClick = (category) => {
        navigate('/' + category.url)
        onClose()
    }

    return <ClickAwayListener onClickAway={ onClose }>
        <div className={ styles.wrapper }>
            <div className={ styles.imagesBtns }>
                {
                    data.map((id, index) => {
                        const category = categories[id]
                        if (!category) return null
                        return <div key={ index } className={ styles.imageCategory }>
                            <div onClick={ () => onClick(category) } style={ { marginBottom: 10, cursor: 'pointer' } }><SText size={ 18 } weight={ 900 } lineHeight={ 23 }
                                color={ Colors.darkGray }>{ category.name }</SText></div>
                            <div className={ styles.imagesGrid }>
                                { category.children.map(subCategory => <ImageBtn onClose={ onClose }
                                    subCategory={ {
                                        ...subCategory,
                                        parent: { url: category.url },
                                    } } />) }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </ClickAwayListener>
}

export default CatalogueNavigator
