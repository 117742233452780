import React from 'react'
import styles from "./AccountNavigationModal.module.scss";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import userZaglushka from '../../assets/images/header/lk.svg'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import { BASE_DOMAIN } from "../../api";
import closeBtn from '../../assets/images/closeModal.svg'
import cn from "classnames";
import accepted from '../../assets/images/userAccount/statusBlock/accepted.svg'
import assembled from '../../assets/images/userAccount/statusBlock/assembled.svg'
import delivered from '../../assets/images/userAccount/statusBlock/delivered.svg'
import like from '../../assets/images/userAccount/statusBlock/like.svg'
import courier from '../../assets/images/userAccount/courierZaglushka.png'
import { colorToFilter } from "../../components/utils/ColorFilter";
import chat from '../../assets/images/userAccount/chatBtn.svg'
import ecoBalls from '../../assets/images/header/ecoBalls.svg'
import help from '../../assets/images/userAccount/help.svg'
import { NavLink, useSearchParams } from "react-router-dom";
import { signOut } from "../../redux/reducers/user";
import { useLastOrder } from "../../components/utils/hooks";
import { getPrettyNumber, getStatusLabelProps } from "./OrdersHistory";
import { shortAddress } from "../../components/utils/other";

const AccountNavigationModal = ({ onClose }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    return <Modal
        open={ true }
        onClose={ onClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={ { display: 'flex', zIndex: 99 } }
    >
        <Box>

            <div onClick={ onClose } className={ styles.closeModal }><img src={ closeBtn } /></div>

            <div className={ styles.modal }>
                <div className={ styles.userInfo }>
                    { user.profilePicture ? <img style={ { width: 44, height: 44, borderRadius: '5px', objectFit: 'cover' } }
                        src={ BASE_DOMAIN + user.profilePicture } /> :
                        <img style={ { width: 44, height: 44, borderRadius: '5px' } } src={ userZaglushka } /> }

                    <SText size={ 26 } weight={ 700 } lineHeight={ 33 } color={ Colors.darkGray }>{ user.name }</SText>
                </div>
                <LastOrder />
                <NavLink onClick={ onClose } to={ '/account/orders-history' }>

                    <div style={ { marginBottom: 38, display: 'flex', gap: 12, cursor: 'pointer' } }>
                        <SText size={ 16 } weight={ 500 } lineHeight={ 20 }
                            color={ Colors.darkGray }>{ 'Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð·Ð°ÐºÐ°Ð·Ð¾Ð²' }</SText>
                        { user.orders.unpaid > 0 &&
                            <div className={ styles.notPaidOrders }><SText size={ 14 } weight={ 700 } lineHeight={ 18 }
                                color={ '#fff' }>{ user.orders.unpaid }</SText>
                            </div> }
                    </div>
                </NavLink>
                <NavLink onClick={ onClose } to={ '/account/bonuses' }>
                    <div style={ { marginBottom: 38, display: 'flex', gap: 12, cursor: 'pointer' } }>
                        <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.darkGray }>{ 'Ð‘Ð¾Ð½ÑƒÑÑ‹' }</SText>
                        {
                            user.bonuses > 0 && <div className={ styles.ecoBalls }>
                                <SText size={ 14 } weight={ 700 } lineHeight={ 20 } color={ '#fff' }>{ user.bonuses }</SText>
                            </div>
                        }
                    </div>
                </NavLink>
                <NavLink onClick={ onClose } to={ '/account/personal-data' }>
                    <div style={ { marginBottom: 38, cursor: 'pointer' } }><SText size={ 16 } weight={ 500 } lineHeight={ 20 }
                        color={ Colors.darkGray }>{ 'ÐœÐ¾Ð¸ Ð´Ð°Ð½Ð½Ñ‹Ðµ' }</SText>
                    </div>
                </NavLink>
                {/* <NavLink to={ 'save-nature' }>
                    <div style={ { marginBottom: 32, cursor: 'pointer' } }><SText size={ 16 } weight={ 500 } lineHeight={ 20 }
                        color={ Colors.darkGray }>{ 'Ð‘ÐµÑ€ÐµÐ¶Ñ‘Ð¼ Ð¿Ñ€Ð¸Ñ€Ð¾Ð´Ñƒ' }</SText>
                    </div>
                </NavLink> */}
                <div className={ styles.separatedLine } />
                <div onClick={ () => {
                    setSearchParams('supportChat')
                    onClose()
                } } className={ styles.helpBtn }>
                    <img src={ help } />
                    <SText size={ 14 } lineHeight={ 14 } weight={ 700 } color={ '#fff' }>{ 'Ð¡Ð»ÑƒÐ¶Ð±Ð° Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸' }</SText>
                </div>
                <div className={ styles.separatedLine } />
                <NavLink to={ '/offer' } onClick={ () => onClose() }>
                    <div style={ { marginBottom: 32, cursor: 'pointer' } }>
                        <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.darkGray }>{ 'ÐžÑ„ÐµÑ€Ñ‚Ð°' }</SText>
                    </div>
                </NavLink>
                <NavLink onClick={ onClose } to={ '/' }>
                    <div onClick={ () => dispatch(signOut()) } style={ { cursor: 'pointer' } }>
                        <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.red }>{ 'Ð’Ñ‹Ñ…Ð¾Ð´ Ð¸Ð· Ð°ÐºÐºÐ°ÑƒÐ½Ñ‚Ð°' }</SText>
                    </div>
                </NavLink>
            </div>
            <div className={ styles.separatedLine } />
            <div onClick={ () => {
                // openChat()
                onClose()
            } } className={ styles.helpBtn }>
                <img src={ help } />
                <SText size={ 14 } lineHeight={ 14 } weight={ 700 } color={ '#fff' }>{ 'Ð¡Ð»ÑƒÐ¶Ð±Ð° Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸' }</SText>
            </div>
            <div className={ styles.separatedLine } />
            <div style={ { marginBottom: 32, cursor: 'pointer' } }>
                <SText size={ 16 } weight={ 500 } lineHeight={ 20 } color={ Colors.darkGray }>{ 'ÐžÑ„ÐµÑ€Ñ‚Ð°' }</SText>
            </div>

            <div style={ { cursor: 'pointer' } }>
                <NavLink onClick={ onClose } to={ '/' }>

                    <SText onClick={ () => dispatch(signOut()) } size={ 16 } weight={ 500 } lineHeight={ 20 }
                        color={ Colors.red }>{ 'Ð’Ñ‹Ñ…Ð¾Ð´ Ð¸Ð· Ð°ÐºÐºÐ°ÑƒÐ½Ñ‚Ð°' }</SText>
                </NavLink>

            </div>
        </Box>
    </Modal>
}

const LastOrder = () => {
    const order = useLastOrder()
    const [searchParams, setSearchParams] = useSearchParams()

    if (!order) return null

    return <div className={ styles.lastOrder }>
        <div style={ { marginBottom: 10 } }><SText size={ 16 } weight={ 700 } lineHeight={ 20 }
            color={ Colors.darkGray }>{ getPrettyNumber(order.NUMBER) }</SText></div>
        <div style={ { marginBottom: 20 } }>
            <div><SText weight={ 500 } size={ 14 } lineHeight={ 17 }
                color={ Colors.darkGray }>{ order.PRETTY_DATE + ' ' + order.TIME }</SText></div>
            <div><SText weight={ 500 } size={ 14 } lineHeight={ 17 }
                color={ Colors.darkGray }>{ shortAddress(order.ADRESS) }</SText></div>
        </div>

        <StatusBlock currentStatus={ getStatusLabelProps(order.newStatus).code } />

        <Courier order={ order } />

        <div className={ styles.lastOrderBtns }>
            { order.COURRIER?.phone && <div className={ styles.chatBtn }>
                <img src={ chat } />
                <SText size={ 12 } lineHeight={ 14 } weight={ 700 } color={ '#fff' }>{ 'Ð¡Ð²ÑÐ·Ð°Ñ‚ÑŒÑÑ' }</SText>
            </div> }
            <div onClick={ () => {
                if (!order.COURRIER?.phone)
                    setSearchParams('orderDetails=' + order.ID)
            } } className={ styles.mapBtn }>
                <SText size={ 12 } lineHeight={ 14 } weight={ 700 }
                    color={ '#fff' }>{ order.COURRIER?.phone ? 'ÐšÑƒÑ€ÑŒÐµÑ€ Ð½Ð° ÐºÐ°Ñ€Ñ‚Ðµ' : 'Ð”ÐµÑ‚Ð°Ð»Ð¸ Ð·Ð°ÐºÐ°Ð·Ð°' }</SText>
            </div>
        </div>
    </div>
}

export const Courier = ({ order }) => {
    if (!order?.COURRIER) return null

    return <div style={ { display: 'flex', gap: 10, alignItems: 'center', marginBottom: 20 } }>
        <div>{ order.COURRIER?.image ? <img src={ order.COURRIER.image } style={ { height: 35 } } /> :
            <img style={ { height: 35 } } src={ courier } /> }</div>
        <div>
            <div><SText size={ 12 } lineHeight={ 15 } weight={ 500 } color={ Colors.darkGray }>{ 'Ð’Ð°Ñˆ Ð·Ð°ÐºÐ°Ð· Ð´Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÑ‚:' }</SText>
            </div>
            <div style={ { display: 'flex', gap: 10, alignItems: 'center' } }>
                <SText size={ 14 } weight={ 700 } lineHeight={ 18 } color={ Colors.darkGray }>{ order.COURRIER.name }</SText>
                <SText size={ 14 } weight={ 700 } lineHeight={ 18 }
                    color={ '#bcbcbc' }>{ Number(order.COURRIER.rate).toFixed(1) }</SText>
            </div>
        </div>
    </div>
}

export const StatusBlock = ({ currentStatus = 3 }) => {

    return <div className={ styles.statusWrapper }>

        <div style={ { position: 'relative' } }>
            <div className={ cn(styles.statusCircle, currentStatus >= 0 && styles.statusActive) }><img
                style={ { filter: currentStatus >= 0 ? colorToFilter('#FFFFFF') : colorToFilter('#BCBCBC') } }
                src={ accepted } /></div>
            <div className={ styles.label }><SText size={ 11 } lineHeight={ 11 } weight={ 700 }
                color={ currentStatus >= 0 ? '#43B02A' : '#bcbcbc' }>{ 'Ð¿Ñ€Ð¸Ð½ÑÑ‚' }</SText>
            </div>
        </div>

        <div className={ styles.line } style={ { background: currentStatus >= 1 ? '#43B02A' : '#DCDCDC' } } />

        <div style={ { position: 'relative' } }>
            <div className={ cn(styles.statusCircle, currentStatus >= 1 && styles.statusActive) }><img
                style={ { filter: currentStatus >= 1 ? colorToFilter('#FFFFFF') : colorToFilter('#BCBCBC') } }
                src={ assembled } />
            </div>
            <div className={ styles.label }><SText size={ 11 } lineHeight={ 11 } weight={ 700 }
                color={ currentStatus >= 1 ? '#43B02A' : '#bcbcbc' }>{ 'ÑÐ¾Ð±Ñ€Ð°Ð½' }</SText>
            </div>
        </div>

        <div className={ styles.line } style={ { background: currentStatus >= 2 ? '#43B02A' : '#DCDCDC' } } />

        <div style={ { position: 'relative' } }>
            <div className={ cn(styles.statusCircle, currentStatus >= 2 && styles.statusActive) }><img
                style={ { filter: currentStatus >= 2 ? colorToFilter('#FFFFFF') : colorToFilter('#BCBCBC') } }
                src={ delivered } />
            </div>
            <div className={ styles.label }><SText size={ 11 } lineHeight={ 11 } weight={ 700 }
                color={ currentStatus >= 2 ? '#43B02A' : '#bcbcbc' }>
                { 'Ð² Ð¿ÑƒÑ‚Ð¸' }
            </SText></div>
        </div>

        <div className={ styles.line } style={ { background: currentStatus >= 3 ? '#43B02A' : '#DCDCDC' } } />

        <div style={ { position: 'relative' } }>
            <div className={ cn(styles.statusCircle, currentStatus === 3 && styles.statusActive) }><img
                style={ { filter: currentStatus >= 3 ? colorToFilter('#FFFFFF') : colorToFilter('#BCBCBC') } }
                src={ like } />
            </div>
            <div style={ { marginLeft: -3 } } className={ styles.label }><SText size={ 11 } lineHeight={ 11 } weight={ 700 }
                color={ currentStatus >= 3 ? '#43B02A' : '#bcbcbc' }>{ 'Ð´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½' }</SText>
            </div>
        </div>

    </div>
}

export default AccountNavigationModal