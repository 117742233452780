import React, { useEffect, useContext, useMemo, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import sendRequest, { BASE_DOMAIN } from "../../api";

import SText from "../../components/SText";

import styles from './NYCheckList.module.scss'

import goBack from '../../assets/images/cart/goBack.svg'
import Image1 from '../../assets/images/nyChecklist/image1.svg'
import Image2 from '../../assets/images/nyChecklist/image2.svg'
import Image3 from '../../assets/images/nyChecklist/image3.svg'

const productsToRender = [
    {
        title: 'ÐœÐ¾Ð¶Ð½Ð¾ ÑƒÐ¶Ðµ Ð·Ð°ÐºÐ°Ð·Ð°Ñ‚ÑŒ',
        items: [
            { type: 'product', id: 15194 },
            { type: 'product', id: 11754 },
            { type: 'product', id: 11107 },
            { type: 'product', id: 354 },
            { type: 'product', id: 9160 },
            { type: 'product', id: 10817 },
            { type: 'product', id: 16345 },
            { type: 'cat', id: 177, image: 'https://api.myfresh.ru/imgs/products_w/13692/thumb/assorti-iz-pomidorchikov-i-ogurchikov-ot-ovcharova-1-5-l-1.JPG' },
            { type: 'product', id: 1913 },
            { type: 'cat', id: 46, image: 'https://api.myfresh.ru/imgs/products_w/12388/thumb/syr-svisstaler-legkiy-20-margot-fromages---250g-1.1641904200.jpg' },
            { type: 'cat', id: 178, image: 'https://api.myfresh.ru/imgs/products_w/17450/thumb/naturalnyy-ketchup-1.png' },
            { type: 'product', id: 17736 },
        ]
    },
    {
        title: 'ÐœÐ¾Ð¶Ð½Ð¾ Ð·Ð°Ð¼Ð¾Ñ€Ð¾Ð·Ð¸Ñ‚ÑŒ',
        items: [
            { type: 'product', id: 2171 },
            { type: 'product', id: 2172 },
            { type: 'product', id: 2173 },
            { type: 'product', id: 10314 },
            { type: 'product', id: 8073 },
            { type: 'product', id: 9146 },
            { type: 'product', id: 9586 },
            { type: 'product', id: 11695 },
            { type: 'cat', id: 106, image: 'https://api.myfresh.ru/imgs/products_w/15911/thumb/bedro-cyplenka-beskostnoe-v-marinade-chili--shashlyk----700g-1.JPG' },
            { type: 'product', id: 12382 },
            { type: 'product', id: 5766 },
            { type: 'product', id: 14500 },
        ]
    },
    {
        title: 'ÐŸÐ¾Ð´ Ð½Ð¾Ð²Ñ‹Ð¹ Ð³Ð¾Ð´ Ñ 20 Ð´ÐµÐºÐ°Ð±Ñ€Ñ',
        items: [
            { type: 'product', id: 3260 },
            { type: 'product', id: 5779 },
            { type: 'product', id: 9770 },
            { type: 'cat', id: 46, image: 'https://api.myfresh.ru/imgs/products/16850/thumb/syr-bri-v-beloy-pleseni-1.jpg' },
            { type: 'cat', id: 9, image: 'https://api.myfresh.ru/imgs/products/4616/thumb/kolbasa-delikatesnaya-iz-koniny---350-g-1.jpg' },
            { type: 'product', id: 18279 },
            { type: 'cat', id: 49, image: 'https://api.myfresh.ru/imgs/products_w/345/thumb/pomidory-uzbekskie-sladkie--1.jpeg' },
            { type: 'cat', id: 2, image: 'https://api.myfresh.ru/imgs/products_w/5138/thumb/hleb-derevenskiy---350g-1.jpg' },
            { type: 'cat', id: 332, image: 'https://api.myfresh.ru/imgs/products_w/5378/thumb/tort-ptiche-moloko-1.jpg' },
            { type: 'cat', id: 11, image: 'https://api.myfresh.ru/imgs/products_w/277/thumb/yabloki-grenni---1-kg-1.1646834009.jpg' },
            { type: 'product', id: 1030 },
            { type: 'product', id: 3360 },
        ]
    }
]

const NYItem = ({ item, groupIndex, checkItem, checkedItems }) => {
    const catalogue = useSelector(state => state.catalogue)

    const itemData = useMemo(() => {
        if (item.type == 'product') {
            const product = catalogue.products[item.id] ? catalogue.products[item.id] : false;

            if (product) {
                return {
                    image: BASE_DOMAIN + product.thumb,
                    title: product.title,
                    url: product.url,
                    value: groupIndex + '_' + item.type + '_' + item.id
                }
            }
        } else if (item.type == 'cat') {
            const cat = catalogue.catV6.map[item.id] ? catalogue.catV6.map[item.id] : false;
            if (cat) {
                return {
                    image: item.image && item.image.length ? item.image : false,
                    title: cat.name,
                    url: cat.url,
                    value: groupIndex + '_' + item.type + '_' + item.id
                }
            }
        }

        return false
    }, [catalogue, item])

    if (!itemData) {
        return null
    }

    return (
        <div className={ styles.itemRow }>
            <input
                id={ itemData.value }
                type="checkbox"
                value={ itemData.value }
                checked={ checkedItems.includes(itemData.value) }
                onChange={ checkItem }
            />
            <label htmlFor={ itemData.value } />
            { itemData.image ? <img src={ itemData.image } /> : <div className={ styles.filler } /> }
            <Link to={ '/' + itemData.url }>{ itemData.title }</Link>
        </div>
    )
}

const updateCheckboxesOnServer = async (data, apikey) => {
    let SEND_DATA = {
        data: data,
        apikey: apikey,
    };

    await sendRequest('saveNYCheckList', SEND_DATA);
}

const getCheckboxesOnServer = async (apikey) => {
    let SEND_DATA = {
        apikey: apikey,
    };

    let data = await sendRequest('getNYCheckList', SEND_DATA);
    if (data !== false) {
        return data
    }

    return []
}

const NYCheckList = () => {
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    const [checkedItems, setCheckedItems] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (user && !checkedItems) {
                const items = await getCheckboxesOnServer(user.apikey)
                setCheckedItems(items)
            } else {
                setCheckedItems([])
            }
        }

        if (!checkedItems) {
            fetchData();
        }
    }, [checkedItems])

    useEffect(() => {
        if (user && checkedItems)
            updateCheckboxesOnServer(checkedItems, user.apikey)
    }, [checkedItems, user])

    const checkItem = (e) => {
        let checked = !e.target.checked;
        let value = e.target.value;

        if (!checked) {
            setCheckedItems([...checkedItems, value])
        } else {
            setCheckedItems(checkedItems.filter((f) => f !== value))
        }
    }

    if (!checkedItems) {
        return null
    }

    return (
        <div className={ styles.wrapper }>
            <div className={ styles.header }>
                <div onClick={ () => navigate('/') } className={ styles.goBack }><img src={ goBack } /></div>
                <SText size={ 16 } lineHeight={ 16 } weight={ 500 }>Ð¡Ð¿Ð¸ÑÐ¾Ðº Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð½Ð° Ð½Ð¾Ð²Ð¾Ð³Ð¾Ð´Ð½Ð¸Ð¹ ÑÑ‚Ð¾Ð» 2023</SText>
            </div>
            <div>
                <h1>Ð¡Ð¿Ð¸ÑÐ¾Ðº Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð½Ð° Ð½Ð¾Ð²Ð¾Ð³Ð¾Ð´Ð½Ð¸Ð¹ ÑÑ‚Ð¾Ð» 2023</h1>
            </div>
            <div className={ styles.nyHolder }>
                <img src={ Image1 } className={ styles.image1 } />
                <img src={ Image2 } className={ styles.image2 } />
                <img src={ Image3 } className={ styles.image3 } />
                {
                    productsToRender.map((group, index) =>
                        <div key={ index }>
                            <span className={ styles.groupHeading }>
                                { group.title }
                            </span>
                            <div className={ styles.itemsHolder }>
                                {
                                    group.items.map((item, i_index) => (
                                        <NYItem
                                            key={ i_index }
                                            groupIndex={ index }
                                            item={ item }
                                            checkItem={ checkItem }
                                            checkedItems={ checkedItems }
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default NYCheckList