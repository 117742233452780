import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import sendRequest from "../../api";
import styles from './DeliveryBanner.module.css'

import whiteClear from '../../assets/images/deliveryBanner/whiteClear.svg'
import congratulate from '../../assets/images/deliveryBanner/congratulate.svg'
import mailCart from '../../assets/images/deliveryBanner/mailCart.png'
import mailCartMobile from '../../assets/images/deliveryBanner/mailCartMobile.png'
import copyedText from '../../assets/images/deliveryBanner/copyedText.svg'
import copyText from '../../assets/images/deliveryBanner/copyText.svg'

export function getCookie() {
    return document.cookie.split('; ').reduce((acc, item) => {
        const [name, value] = item.split('=')

        return { ...acc, [name]: value }
    }, {})
}

export function deliveryBannerWasShownToUser() {
    let cookie = getCookie()

    if (cookie.deliveryBannerWasShownToUser) {
        return true
    }
    return false
}

const recordCookie = (name, value, minutes) => {
    var date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
    document.cookie = name + "=" + value + expires + "; path=/";
}

const DeliveryBanner = ({ show }) => {

    const user = useSelector(state => state.user)
    const ref = useRef(null)
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)
    const [copyed, setCopyed] = useState(false)
    const [clicked, setClicked] = useState()
    const [bannerIsShown, setBannerShown] = useState(deliveryBannerWasShownToUser())

    /*
    useEffect(() => {
        if (ref.current)
            ref.current.focus()
    }, [])
    */




    async function ValidateEmail(email) {
        var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        setError(!re.test(String(email).toLowerCase()))
        if (re.test(String(email).toLowerCase())) {

            const payload = {
                email: email
            }

            sendRequest('getPromoEventDelivery', payload)

            recordCookie('deliveryBannerWasShownToUser', 'true', 5)

            setClicked(true)
        }
    }

    const width = window.innerWidth

    if (bannerIsShown || user.apikey !== null) {
        return null
    }

    return null

    setTimeout(() => {
        recordCookie('deliveryBannerWasShownToUser', 'true', 5)
    }, 500)

    return (
        <div className={ styles.overlay }>
            <div className={ styles.modalCloser } onClick={ () => show(false) } />
            <div style={ { height: (width <= 800 && clicked) ? 272 : 448 } } className={ styles.modal }>
                <div className={ width > 800 ? styles.mailCart : styles.mobileMailCart }><img
                    src={ width > 800 ? mailCart : mailCartMobile } /></div>
                { !clicked && <div className={ styles.content }>
                    <div className={ styles.title }>Ð”Ð°Ñ€Ð¸Ð¼ 2 Ð½ÐµÐ´ÐµÐ»Ð¸ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾Ð¹ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸ Ð½Ð¾Ð²Ñ‹Ð¼ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°Ð¼.</div>
                    <div className={ styles.text }>
                        <div className={ styles.item }>Ð Ð°Ð±Ð¾Ñ‚Ð°ÐµÐ¼ 24 Ñ‡Ð°ÑÐ°</div>
                        <div className={ styles.item }>Ð‘Ñ‹ÑÑ‚Ñ€Ð°Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð·Ð° 90 Ð¼Ð¸Ð½.</div>
                        <div className={ styles.item }>Ð‘Ð¾Ð»ÑŒÑˆÐ¾Ð¹ Ð°ÑÑÐ¾Ñ€Ñ‚Ð¸Ð¼ÐµÐ½Ñ‚ Ð¾Ñ€Ð³Ð°Ð½Ð¸Ñ‡ÐµÑÐºÐ¸Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²</div>
                        <div className={ styles.item }>ÐÐ°Ñ‡Ð¸ÑÐ»ÑÐµÐ¼ Ð±Ð¾Ð½ÑƒÑÐ½Ñ‹Ðµ Ð±Ð°Ð»Ð»Ñ‹ Ð·Ð° ÐºÐ°Ð¶Ð´Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð·</div>
                        <div className={ styles.item }>ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð»ÑŽÐ±Ñ‹Ð¼ ÑƒÐ´Ð¾Ð±Ð½Ñ‹Ð¼ ÑÐ¿Ð¾ÑÐ¾Ð±Ð¾Ð¼</div>
                    </div>
                    { width > 800 ? <>
                        <div><input style={ { border: error ? '1px solid red' : '1px solid #c6c6c6' } } ref={ ref }
                            value={ email }
                            onChange={ (e) => setEmail(e.target.value) }
                            placeholder={ 'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ e-mail' } type="text" /></div>
                        <div onClick={ () => ValidateEmail(email) } className={ styles.btn }>ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½ÑƒÑŽ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ Ð½Ð°
                            2
                            Ð½ÐµÐ´ÐµÐ»Ð¸
                        </div>
                    </> : null }
                </div> }
                { (!clicked && width <= 800) ? <div className={ styles.mailMobile }>
                    <div><input style={ { border: error ? '1px solid red' : '1px solid #c6c6c6' } } ref={ ref }
                        value={ email }
                        onChange={ (e) => setEmail(e.target.value) }
                        placeholder={ 'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ e-mail' } type="text" /></div>
                    <div onClick={ () => ValidateEmail(email) } className={ styles.btn }>ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½ÑƒÑŽ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ Ð½Ð°
                        2
                        Ð½ÐµÐ´ÐµÐ»Ð¸
                    </div>
                </div> : null }
                { clicked && <div className={ styles.secondContent }>
                    <div style={ { marginBottom: width > 800 ? 21 : 9 } }><img src={ congratulate }
                        alt="" /></div>
                    <div className={ styles.secondContentText }>Ð’Ð°Ñˆ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ <br /> Ð½Ð° 2 Ð½ÐµÐ´ÐµÐ»Ð¸ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾Ð¹ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸:</div>
                    <div style={ { display: 'flex', gap: 20, alignItems: 'center' } }>
                        <div style={ { opacity: copyed ? 0.5 : 1 } } className={ styles.promo }>ZAKAZ</div>
                        <div>{ copyed ? <img src={ copyedText } /> : <img onClick={ () => {
                            navigator.clipboard.writeText('ZAKAZ')
                            setCopyed(true)
                        } } style={ { cursor: 'pointer' } } src={ copyText } /> }</div>
                    </div>
                </div> }
                <div onClick={ () => show(false) } className={ styles.whiteClear }><img src={ whiteClear } /></div>
            </div>
        </div>
    );
};

export default DeliveryBanner;