import React, { useRef } from "react";
import styles from "./Showcase.module.css"
import arrow from '../../assets/images/cart/goBack.svg'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BASE_DOMAIN } from "../../api";
import Slider from "react-slick";
import "./style.css";

const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500
};

const Showcase = () => {
    const slider = useRef(null)
    const config = useSelector(state => state.config.data)
    const navigate = useNavigate()

    function next() {
        if (slider.current) {
            slider.current.slickNext()
        }
    }

    function prev() {
        if (slider.current) {
            slider.current.slickPrev()
        }
    }

    const onClick = (item) => {
        if (typeof window.yaCounter99927635 !== "undefined") {
            window.yaCounter99927635.reachGoal('BANNER_CLICK');
        }
        navigate(item.link)
    }

    return <div>
        <div className='App'>
            <div className={ styles.banner }>
                <Slider ref={ slider } { ...settings } className="carousel">
                    {
                        config.map((item, index) => <div key={ index } className={ styles.image } onClick={ () => onClick(item) }>
                            <img src={ BASE_DOMAIN + item.image } />
                        </div>)
                    }
                </Slider>
                {/* <>
                    <div className={ styles.backBtn } style={ { position: 'absolute', top: '45%', left: '-45px' } }>
                        <div className="button" onClick={ prev }>
                            <img src={ arrow } />
                        </div>
                    </div>
                    <div className={ styles.nextBtn } style={ { position: 'absolute', top: '45%', right: '-45px' } }>
                        <div className="button" onClick={ next }>
                            <img src={ arrow } />
                        </div>
                    </div>
                </> */}
            </div>
        </div>
    </div>
}

export default Showcase