import axios from "axios";

export const BASE_URL = 'https://myfresh.ru/api.php'
export const BASE_DOMAIN = 'https://myfresh.ru'

async function sendRequest(action, payload = {}, debug = false) {
    return await axios({
        baseURL: BASE_URL,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Accept': 'application/json'
        },
        data: {
            action,
            ...payload
        },
        credentials: 'include',
    })
        .then(r => {
            if (debug)
                console.log({ request: { action, ...payload }, response: r.data.data })
            return r.data?.data
        })
        .catch(error => console.log(error))
}

export default sendRequest
